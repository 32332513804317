import {
  Alert,
  AlertTitle,
  Button,
  Divider,
  Grid,
  Stack,
  Typography,
  useTheme,
} from "@mui/material";
import React from "react";
import MainCard from "components/Common/Cards/MainCard";
import type { Account, PartnerData } from "types";
import usePartnerStatus from "./utils/usePartnerStatus";
import { BUYER } from "codes";

const LeadPartnerTermSheetInfo = ({
  navigateTab,
  partner,
  partnerDetails,
}: {
  navigateTab: (index: number) => void;
  partner: Account;
  partnerDetails: PartnerData;
}) => {
  const theme = useTheme();
  const isPartnerBuyer = partner.profile === BUYER?.toLowerCase();

  const {
    isPartnerAdditionalInformationFilledUp,
    isPartnerTradeDetailsInformationFilledUp,
  } = usePartnerStatus(partnerDetails);
  const disabled = !(
    isPartnerAdditionalInformationFilledUp &&
    (!isPartnerBuyer || isPartnerTradeDetailsInformationFilledUp)
  );

  if (disabled)
    return (
      <Grid item xs={12}>
        <Alert severity="error">
          <Stack spacing={1}>
            <AlertTitle sx={{ fontSize: "1.5em", fontWeight: "bold" }}>
              Termsheet Info (Please complete the Details to proceed)
            </AlertTitle>
            <Divider />
            <Typography fontSize="1.5em">
              Please ensure that the
              <Button onClick={() => navigateTab(0)}>
                Additional Information
              </Button>
              {isPartnerBuyer && (
                <React.Fragment>
                  &<Button onClick={() => navigateTab(1)}>Trade Details</Button>{" "}
                </React.Fragment>
              )}
              regarding <strong>{partner?.name}</strong> are completed to
              continue to termsheet as per the business requirements.
            </Typography>
          </Stack>
        </Alert>
      </Grid>
    );
    
  return (
    <MainCard
      border
      content
      boxShadow
      sx={{ minWidth: "60vw" }}
      shadow={theme.shadows[16]}
      title={
        <Typography fontWeight="bold" variant="h3">
          Additional Details
        </Typography>
      }
      footer={
        <Grid xs={12} lg={2} ml="auto" my={1} justifyContent="flex-end">
          Partner Termsheet Info Footer
        </Grid>
      }
    >
      View Partner Termsheet Info
    </MainCard>
  );
};

export default LeadPartnerTermSheetInfo;

import { Warning } from "@mui/icons-material";
import {
  Checkbox,
  Chip,
  Container,
  Divider,
  FormControl,
  FormControlLabel,
  FormGroup,
  Radio,
  RadioGroup,
  Stack,
  Typography,
  useTheme,
} from "@mui/material";
import Grid from "@mui/material/Grid";
import {
  useGetEligibilitiesQuery,
  useGetEligibilityByIsoQuery,
} from "api/api-legal";
import {
  useApproveLegalMapMutation,
  useEditMapSummaryMutation,
  useGetLegalMapApprovalsQuery,
  useGetMapSummaryQuery,
} from "api/api-maps";
import { useGetApproversQuery, useGetCurrentUserQuery } from "api/api-users-v2";
import { BUYER, SUPPLIER } from "codes";
import MainCard from "components/Common/Cards/MainCard";
import Drop from "components/Common/Drop";
import CheckboxField from "components/Common/FormFields/CheckboxField";
import LabelViewOnly from "components/Common/FormFields/LabelViewOnly";
import Layout from "components/Common/Layout";
import Modal from "components/Common/Modal";
import NoData from "components/Common/NoData";
import SkeletonLoad from "components/Common/SkeletonLoad";
import VariableDropdown from "components/Common/VariableDropdown";
import VariableInput from "components/Common/VariableInput";
import { Form, Formik } from "formik";
import groupBy from "helpers/groupBy";
import toProperCase from "helpers/propercase";
import useIsMobile from "hooks/useIsMobile";
import { MapContext } from "pages/maps/map";
import { PERMISSIONS } from "permissions";
import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { useAppSelector } from "redux/hooks";
import GoverningLaws from "./GoverningLaws";

const Legal = () => {
  const id = React.useContext(MapContext);
  const conditionTexts = [
    "Consent Clause in Invoice",
    "Consent Clause in BC",
    "For USA buyers, UCC Filing",
  ];
  const {
    data: mapData,
    refetch: refetchMapSummary,
    isLoading,
  } = useGetMapSummaryQuery(id ?? "", { skip: !id });

  const [showApprovalModal, setShowApprovalModal] = useState({
    visible: false,
    type: "",
  });

  const { data: currentUser } = useGetCurrentUserQuery();
  const { data: legalApproval, isLoading: isLoadingLegalApproval } =
    useGetLegalMapApprovalsQuery(id ?? "", {
      skip: id === undefined,
    });
  const [modifyApproval, { isLoading: isLoadingApprovalModification }] =
    useApproveLegalMapMutation();
  const { data: approvers = [], isFetching } = useGetApproversQuery("LEGAL");

  const theme = useTheme();
  const isMobile = useIsMobile();
  const modalOverlaySx = {
    backgroundColor: "white !important",
    width: "50%",
    alignItems: "center",
    justifyContent: "center",
    borderRadius: "4ch",
    padding: "2ch",
    fontSize: `${theme.typography.fontSize}px !important`,
    fontFamily: `${theme.typography.fontFamily} !important`,
    height: "inherit !important",
    fontWeight: "inherit !important",
  };
  const modalSx = {
    alignItems: "center",
    justifyContent: "center",
    overflow: "hidden",
  };

  const PHASE: Record<string, string> = {
    approve: "Approve",
    revoke: "Revoke",
    reject: "Reject",
  };

  const mapApprovers = approvers?.map((item) => ({
    id: item.contact_id,
    approver_name: `${item.first_name} ${item.last_name}`,
  }));
  const {
    data: countries,
    isFetching: isFetchingCountries,
    refetch,
  } = useGetEligibilitiesQuery(null);
  const {
    data: countryLegalDetails,
    isFetching: isFetchingCountryLegalDetails,
    refetch: refetchCountryLegalDetails,
  } = useGetEligibilityByIsoQuery(mapData?.governing_law ?? "", {
    skip: !mapData?.governing_law,
  });

  const [editMapSummary] = useEditMapSummaryMutation();
  const editor = (field: string, value: any, friendlyLabel: string) => {
    id &&
      editMapSummary({
        map_name: id,
        data: { [field]: value },
      })
        .unwrap()
        .then(() => {
          toast.success(`${friendlyLabel} Updated`);
        })
        .catch((err) => toast.error(`Error: ${err?.message}`));
  };

  useEffect(() => {
    if (mapData !== undefined)
      if (
        mapData?.governing_law === undefined ||
        mapData?.governing_law === ""
      ) {
        editor(
          "governing_law",
          mapData?.buyer_country_iso3,
          "Governing Law set to Buyer Country by default"
        );
        refetchMapSummary()
          .then(() => refetch())
          .then(() => refetchCountryLegalDetails());
      }
  }, [mapData]);
  const USER_PERMISSIONS = useAppSelector(
    (state) => state.appState.user_permission
  );

  const isMapApproved = legalApproval?.approval_status === "Approved";
  const isMapRejected = legalApproval?.approval_status === "Rejected";

  const isFieldDisabled =
    !USER_PERMISSIONS.includes(PERMISSIONS.map_access_legal) ||
    isMapApproved ||
    isMapRejected;
  return (
    <Layout
      title="Legal Review"
      headerConfig={{
        syncAction: () =>
          refetchMapSummary()
            .then(() => refetch())
            .then(() => refetchCountryLegalDetails()),
        right: 4,
        xs: { right: 12 },
        endComponent: isMapApproved ? (
          <Grid item>
            <Chip
              variant="outlined"
              color="success"
              label="Approved"
              sx={{ fontWeight: "bold" }}
            />
          </Grid>
        ) : isMapRejected ? (
          <Grid item>
            <Chip
              variant="outlined"
              color="error"
              label="Rejected"
              sx={{ fontWeight: "bold" }}
            />
          </Grid>
        ) : undefined,
      }}
      primary={
        USER_PERMISSIONS.includes(PERMISSIONS.approval_level_head_legal)
          ? {
              children:
                isMapApproved || isMapRejected ? "Revoke Map" : "Approve Map",
              fullWidth: true,
              variant: "contained",
              color: isMapApproved || isMapRejected ? "warning" : "success",
              // disabled: idMapApproved,
              onClick: () => {
                const type =
                  isMapApproved || isMapRejected ? "revoke" : "approve";
                setShowApprovalModal({ visible: true, type: type });
              },
            }
          : undefined
      }
      secondary={
        USER_PERMISSIONS.includes(PERMISSIONS.approval_level_head_legal)
          ? {
              children: "Reject Map",
              fullWidth: true,
              variant: "contained",
              disabled: isMapApproved || isMapRejected,
              color: "error",
              onClick: () =>
                setShowApprovalModal({ visible: true, type: "reject" }),
            }
          : undefined
      }
    >
      <Formik
        initialValues={{
          governingLawJusrisdiction: "",
          noticeOfAssignment: false,
          credit_note_clause: false,
          set_off_clause: false,
          legal_comment: "",
          shared_in_invoice: false,
          legalApprovalRemark: "",
          approver_name: `${currentUser?.first_name} ${currentUser?.last_name}`,
          approver: currentUser?.id ?? "",

          assignment_notice_signed_by_buyer:
            mapData?.assignment_notice_signed_by_buyer ?? false,
          assignment_restriction: mapData?.assignment_restriction,
          credit_note_clause_in_buyer_confirmation:
            mapData?.credit_note_clause_in_buyer_confirmation,
          set_off_clause_in_buyer_confirmation:
            mapData?.set_off_clause_in_buyer_confirmation,
          legal_comments: mapData?.legal_comments,
          legal_shared_in_invoice: mapData?.legal_shared_in_invoice,
          // Governing law by default is of the buyer country
          governing_law: mapData?.governing_law ?? mapData?.buyer_country_iso3,
        }}
        enableReinitialize
        onSubmit={() => {}}
      >
        {(formikProps) => {
          return (
            <Form>
              <Container maxWidth="lg" sx={{ mt: 3, width: "100%" }}>
                <Stack spacing={2}>
                  {mapData && (
                    <MainCard content>
                      {(isFetchingCountryLegalDetails ||
                        isFetchingCountries) && <SkeletonLoad bars={5} />}
                      {!isFetchingCountries && countries && (
                        <Stack spacing={1}>
                          <Stack>
                            <Typography variant="h4" fontWeight="bold">
                              Governing Laws
                            </Typography>
                            <Drop
                              name="governing_law"
                              keyValue="name"
                              value={
                                countries?.find(
                                  (country) =>
                                    country.id ===
                                    formikProps?.values?.governing_law
                                )?.name
                              }
                              data={countries}
                              setValue={(value) => {
                                const selected_iso3 = countries?.find(
                                  (country) => country.name === value
                                )?.id;
                                formikProps.setFieldValue(
                                  "governing_law",
                                  selected_iso3
                                );
                                editor(
                                  "governing_law",
                                  selected_iso3,
                                  "Governing Law"
                                );
                              }}
                              disabled={isFieldDisabled}
                            />
                          </Stack>
                          {countryLegalDetails?.conditions && (
                            <Typography
                              sx={{
                                bgcolor: "#a2a6c338",
                                textAlign: "start",
                                outline: "1px dashed #a2a6c3",
                                borderRadius: "10px",
                              }}
                            >
                              <Stack
                                justifyContent="start"
                                alignItems="center"
                                spacing={1}
                                p={2}
                              >
                                {countryLegalDetails?.conditions?.length ===
                                  0 && <NoData text="No Legal Guidelines" />}
                                {countryLegalDetails?.conditions?.length > 0 &&
                                  Object.entries(
                                    groupBy(
                                      countryLegalDetails?.conditions,
                                      "party"
                                    )
                                  )?.map(([party, conditions]) => {
                                    return (
                                      <Grid container>
                                        <Grid item xs={12}>
                                          <Typography fontWeight="bold">
                                            {`As a ${toProperCase(party)}`}
                                          </Typography>
                                        </Grid>
                                        {conditions?.map((condition) => (
                                          <>
                                            <Grid item xs={12}>
                                              <Typography>
                                                {condition?.condition}
                                              </Typography>
                                            </Grid>
                                            {Boolean(
                                              condition?.documents?.length
                                            ) && (
                                              <li>
                                                {condition.documents?.map(
                                                  (doc) => (
                                                    <li>{doc.display_name}</li>
                                                  )
                                                )}
                                              </li>
                                            )}
                                          </>
                                        ))}
                                      </Grid>
                                    );
                                  })}
                              </Stack>
                            </Typography>
                          )}
                          <GoverningLaws
                            iso3={mapData?.seller_country_iso3}
                            type={SUPPLIER}
                          />
                          <Divider />
                          <GoverningLaws
                            iso3={mapData?.buyer_country_iso3}
                            type={BUYER}
                          />
                        </Stack>
                      )}
                    </MainCard>
                  )}

                  <Stack spacing={1}>
                    <LabelViewOnly label="Notice of Assignment signed by buyer" />
                    <FormGroup>
                      <FormControl disabled={isFieldDisabled}>
                        <RadioGroup
                          aria-labelledby="assignment_notice_signed_by_buyer"
                          name="assignment_notice_signed_by_buyer"
                          value={
                            formikProps.values.assignment_notice_signed_by_buyer
                          }
                          onChange={(e) => {
                            formikProps.setFieldValue(
                              "assignment_notice_signed_by_buyer",
                              e.target.value === "true",
                              true
                            );
                            editor(
                              "assignment_notice_signed_by_buyer",
                              e.target.value === "true",
                              "Notice of Assignment signed by buyer"
                            );
                          }}
                          row
                        >
                          <Grid container spacing={1}>
                            <Grid item>
                              <FormControlLabel
                                value={true}
                                control={<Radio />}
                                sx={{ alignItems: "center" }}
                                label="Yes"
                              />
                            </Grid>
                            <Grid item>
                              <FormControlLabel
                                value={false}
                                control={<Radio />}
                                sx={{ alignItems: "center" }}
                                label="No"
                              />
                            </Grid>
                          </Grid>
                        </RadioGroup>
                      </FormControl>
                    </FormGroup>
                  </Stack>

                  <Stack spacing={1}>
                    <LabelViewOnly label="Assignment restrictions" />
                    {conditionTexts.map((condition) => {
                      const checked =
                        formikProps.values.assignment_restriction?.includes(
                          condition
                        );

                      return (
                        <FormControlLabel
                          control={
                            <Checkbox checked={checked} value={condition} />
                          }
                          label={condition}
                          name="assignment_restriction"
                          disabled={isFieldDisabled}
                          onChange={(e, checked) => {
                            const value = (e?.target as any)?.value;
                            const restrictions =
                              formikProps.values.assignment_restriction ?? [];
                            if (checked) {
                              formikProps.setFieldValue(
                                "assignment_restriction",
                                [...restrictions, value],
                                true
                              );
                              editor(
                                "assignment_restriction",
                                [...restrictions, value],
                                "Assignment restriction"
                              );
                            } else {
                              formikProps.setFieldValue(
                                "assignment_restriction",
                                restrictions.filter((item) => item !== value),
                                true
                              );
                              editor(
                                "assignment_restriction",
                                restrictions.filter((item) => item !== value),
                                "Assignment restriction"
                              );
                            }
                          }}
                        />
                      );
                    })}
                  </Stack>
                  <Stack spacing={1}>
                    <LabelViewOnly label="Credit Note Clause in Buyer Confirmation" />
                    <FormGroup>
                      <FormControl disabled={isFieldDisabled}>
                        <RadioGroup
                          aria-labelledby="credit_note_clause_in_buyer_confirmation"
                          name="credit_note_clause_in_buyer_confirmation"
                          value={
                            formikProps.values
                              .credit_note_clause_in_buyer_confirmation
                          }
                          onChange={(e) => {
                            formikProps.setFieldValue(
                              "credit_note_clause_in_buyer_confirmation",
                              e.target.value === "true",
                              true
                            );
                            editor(
                              "credit_note_clause_in_buyer_confirmation",
                              e.target.value === "true",
                              "Credit Note Clause in Buyer Confirmation"
                            );
                          }}
                          row
                        >
                          <Grid container spacing={1}>
                            <Grid item>
                              <FormControlLabel
                                value={true}
                                control={<Radio />}
                                sx={{ alignItems: "center" }}
                                label="Yes"
                              />
                            </Grid>
                            <Grid item>
                              <FormControlLabel
                                value={false}
                                control={<Radio />}
                                sx={{ alignItems: "center" }}
                                label="No"
                              />
                            </Grid>
                          </Grid>
                        </RadioGroup>
                      </FormControl>
                    </FormGroup>
                  </Stack>
                  <Stack spacing={1}>
                    <LabelViewOnly label="Set-off Clause in Buyer Confirmation" />
                    <FormGroup>
                      <FormControl disabled={isFieldDisabled}>
                        <RadioGroup
                          value={
                            formikProps.values
                              .set_off_clause_in_buyer_confirmation
                          }
                          aria-labelledby="set_off_clause_in_buyer_confirmation"
                          name="set_off_clause_in_buyer_confirmation"
                          onChange={(e) => {
                            formikProps.setFieldValue(
                              "set_off_clause_in_buyer_confirmation",
                              e.target.value === "true",
                              true
                            );
                            editor(
                              "set_off_clause_in_buyer_confirmation",
                              e.target.value === "true",
                              "Set-off Clause in Buyer Confirmation"
                            );
                          }}
                          row
                        >
                          <Grid container spacing={1}>
                            <Grid item>
                              <FormControlLabel
                                value={true}
                                control={<Radio />}
                                sx={{ alignItems: "center" }}
                                label="Yes"
                              />
                            </Grid>
                            <Grid item>
                              <FormControlLabel
                                value={false}
                                control={<Radio />}
                                sx={{ alignItems: "center" }}
                                label="No"
                              />
                            </Grid>
                          </Grid>
                        </RadioGroup>
                      </FormControl>
                    </FormGroup>
                  </Stack>
                  <VariableInput
                    label="Legal Comment, if any"
                    name="legal_comments"
                    value={formikProps.values.legal_comments}
                    disabled={isFieldDisabled}
                    noHover={isFieldDisabled}
                    multiline
                    fullWidth
                    style={{ width: "100%" }}
                    {...formikProps}
                    handleSave={(value) => {
                      if (!isFieldDisabled)
                        editor("legal_comments", value, "Legal Comment");
                    }}
                  />
                  <CheckboxField
                    label="To be shared in Invoice verification details"
                    disabled={isFieldDisabled}
                    name="legal_shared_in_invoice"
                    value={formikProps.values.legal_shared_in_invoice}
                    getFieldValue={(value) => {
                      formikProps.setFieldValue(
                        "legal_shared_in_invoice",
                        value
                      );
                      editor(
                        "legal_shared_in_invoice",
                        value,
                        "Shared in Invoice"
                      );
                    }}
                    {...formikProps}
                  />
                </Stack>
              </Container>
              <Modal
                Header={
                  <Stack
                    direction="row"
                    justifyContent="space-between"
                    spacing={1}
                  >
                    <Warning color="warning" />
                    <Typography
                      fontWeight="bold"
                    >{`${PHASE[showApprovalModal?.type]} Legal Review on ${id}`}</Typography>
                  </Stack>
                }
                message={
                  <Grid item xs={12}>
                    <Stack spacing={2}>
                      <Stack spacing={0.5} direction="row" flexWrap="wrap">
                        <Typography>{`You are about to ${showApprovalModal?.type} the legal review on`}</Typography>
                        <Typography
                          fontWeight="bold"
                          color="error"
                        >{`${id}`}</Typography>
                        <Typography>This action is irreversible. An email will be sent out to all the members of the Credit Committee, following this action.</Typography>
                        <Typography>Do you want to proceed?</Typography>
                      </Stack>
                      <VariableDropdown
                        name="approver"
                        keyValue="approver_name"
                        label="Approver"
                        data={mapApprovers}
                        value={formikProps.values["approver_name"]}
                        setValue={(value) => {
                          const approver_id = mapApprovers.find(
                            (item) => item.approver_name === value
                          )?.id;
                          formikProps.setFieldValue("approver", approver_id);
                          formikProps.setFieldValue("approver_name", value);
                        }}
                      />
                      <VariableInput
                        label="Approval Remarks"
                        name="legalApprovalRemark"
                        placeholder="Enter Remarks (if any)"
                        multiline
                        fullWidth
                        style={{ width: "100%" }}
                        {...formikProps}
                      />
                    </Stack>
                  </Grid>
                }
                sx={modalSx}
                width={isMobile ? "90%" : "40%"}
                height="auto"
                modalContentSx={{ height: "auto" }}
                open={showApprovalModal?.visible}
                modalOverlaySx={modalOverlaySx}
                modalFooterSx={{
                  maxWidth: "100%",
                  justifyContent: "space-between",
                }}
                onClose={() =>
                  setShowApprovalModal({ visible: false, type: "" })
                }
                closeButton
                primary={{
                  variant: "contained",
                  fullWidth: true,
                  children: "Confirm",
                  loading: isLoadingApprovalModification,
                  onClick: () =>
                    id &&
                    modifyApproval({
                      map_name: id,
                      data: {
                        approver: formikProps.values["approver"],
                        approval_status:
                          showApprovalModal?.type === "approve"
                            ? "Approved"
                            : showApprovalModal?.type === "revoke"
                              ? "Revoked"
                              : "Rejected",
                        comments: formikProps.values["legalApprovalRemark"],
                      },
                    })
                      .unwrap()
                      .then(() => {
                        setShowApprovalModal({ visible: false, type: "" });
                        toast.success(
                          `Legal Map ${showApprovalModal?.type}d`
                        );
                      })
                      .catch(() => {
                        setShowApprovalModal({ visible: false, type: "" });
                        toast.error("Error Approving Legal Map");
                      }),
                }}
                secondary={{
                  variant: "outlined",
                  fullWidth: true,
                  color: "error",
                  children: `Cancel`,
                  onClick: () =>
                    setShowApprovalModal({ visible: false, type: "" }),
                }}
              />
            </Form>
          );
        }}
      </Formik>
    </Layout>
  );
};

export default Legal;

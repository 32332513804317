import type { CLDetails } from "types";

export const initialValues: CLDetails = {
  id: "",
  reqId: "",

  buyerName: "",
  buyerRegNo: "",
  buyerAddress: "",
  buyerCountry: "",

  buyerAnnualRevenue: 0.0,
  dateRequested: undefined,
  insurerResponse: undefined,
  salesLead: {
    email: "",
    first_name: "",
    id: "",
    last_name: "",
    mobile: "",
    opted_marketing_updates: false,
    title: "",
  },
  salesName: "",
  clStatus: "CL_PENDING",
  internalStatus: "CL_PENDING",
  reqAmount: 0,
  approved_amount: 0,
  atradius_amount: 0,
  comment: "",
  jobPosition: "",
  credit_limit_request_internal_amount: 0,

  buyerIsIdentified: false,
  buyerSource: "",
  creator: "",
  contact_description: "",
  email: "",
  client_name: "",
  credit_committee_amount: 0,
};

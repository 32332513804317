import LoadingButton from "@mui/lab/LoadingButton";
import { Divider, Grid, InputAdornment, Typography } from "@mui/material";
import { useGetCountriesByTypeQuery } from "api/api-misc";
import { useCreateLeadMutation } from "api/api-origination";
import { BUYER, SELLER, SUPPLIER } from "codes";
import Drop from "components/Common/Drop";
import Input from "components/Common/Input";

import { useGetAccountingSoftwaresQuery } from "api/api-accounts";
import { useGetIndustriesQuery } from "api/api-company";
import DateInput from "components/Common/DateInput";
import SkeletonLoad from "components/Common/SkeletonLoad";
import { Form, Formik } from "formik";
import isObjectEmpty from "helpers/isObjectEmpty";
import valueCleaner from "helpers/valueCleaner";
import useIsMobile from "hooks/useIsMobile";
import moment from "moment";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import type { Country, RegisterLead } from "types";
import ShippingAddress from "../Details/ShippingAddress";
import Goods from "../Goods";
import { defaultValues, validationSchema } from "./validationSchema";

const CreateLead = ({ onEnd }: { onEnd: (internal_name: string) => void }) => {
  const { t } = useTranslation();
  const isMobile = useIsMobile();

  const [createLead, { isLoading: isLoadingCreateLead }] =
    useCreateLeadMutation();

  const { data: industries, isFetching: isFetchingIndustries } =
    useGetIndustriesQuery();
  const {
    data: accountingSoftwares,
    isFetching: isFetchingAccountingSoftware,
  } = useGetAccountingSoftwaresQuery();

  return (
    <Formik
      enableReinitialize
      initialValues={defaultValues}
      validationSchema={validationSchema}
      onSubmit={() => {}}
    >
      {(formikProps) => {
        const { errors, setErrors, values, dirty } = formikProps;
        const { company_type, name } = values;
        const isSeller =
          company_type?.toLowerCase() === SUPPLIER.toLowerCase() ||
          company_type?.toLowerCase() === SELLER ||
          company_type === t("supplier");
        const userType = isSeller ? SELLER : BUYER.toLowerCase();
        const { data: countries, isFetching: isFetchingCompanies } =
          useGetCountriesByTypeQuery(userType, {
            skip: !Boolean(company_type) || !Boolean(userType),
          });

        const setter = (value: string) => {
          const selected = countries?.find((item) => item.name === value);
          formikProps.setFieldValue("country", selected?.name, true);
          formikProps.setFieldValue(
            "shipping_address.country",
            selected?.name,
            true
          );
          formikProps.setFieldValue("iso3", selected?.id, true);
          formikProps.setFieldValue(
            "shipping_address.iso3",
            selected?.id,
            true
          );
        };

        const createLeadActions = () => {
          const createleadRequestBody = {
            name: values?.name,
            country: values?.iso3,
            company_type:
              company_type.toLowerCase() === SUPPLIER.toLowerCase()
                ? "seller"
                : company_type.toLowerCase() === BUYER.toLowerCase()
                  ? BUYER.toLowerCase()
                  : "seller",
            incorporation_date: moment(values.incorporation_date).format(
              "YYYY-MM-DD"
            ),
            reg_no: values.reg_no,
            address_line1: values.address_line1,
            address_line2: values.address_line2,
            city: values.city,
            postcode: values.postcode,
            shipping_address: {
              address_line1: values.shipping_address?.address_line1,
              address_line2: values.shipping_address?.address_line2,
              city: values.shipping_address?.city,
              postcode: values.shipping_address?.postcode,
              country: values.shipping_address?.iso3 ?? values?.iso3,
            },
            same_shipping_operation_address:
              values.same_shipping_operation_address,
            industry: values.industry,
            accounting_software: values.accounting_software,
            number_of_employees: values.number_of_employees,
            annual_revenue: valueCleaner(`${values.annual_revenue}`),
            goods_category: values.goods_category,
            other_accounting_software: values.other_accounting_software,
          };

          createLead(createleadRequestBody as RegisterLead)
            .unwrap()
            .then((res) => {
              const company_id = res?.id;
              const { internal_name } = res;
              setErrors({});
              formikProps.setFieldValue("companyId", company_id, true);
              toast(`Lead Created: ${name}`, {
                type: "success",
              });
              if (onEnd) onEnd(internal_name);
            })
            .catch((e) => {
              toast(e?.message ?? "An error occured", {
                type: "error",
              });
            });
        };
        const setterAccountingSoftware = (value: string) => {
          formikProps.setTouched({ accounting_software: true }, true);
          formikProps.setFieldValue("accounting_software", value, true);
        };
        const setterNumberOfEmployees = (value: string) => {
          formikProps.setTouched({ number_of_employees: true }, true);
          formikProps.setFieldValue("number_of_employees", value, true);
        };
        return (
          <Form>
            <Grid
              container
              justifyContent="space-between"
              alignItems="center"
              spacing={2}
              marginBottom={isMobile ? "5vh" : 0}
            >
              <Grid item xs={12} lg={6}>
                <Drop
                  label="Lead Type"
                  multilevel={false}
                  data={[
                    {
                      value: SUPPLIER.toLowerCase(),
                      name: SUPPLIER.toLowerCase(),
                    },
                    {
                      value: BUYER.toLowerCase(),
                      name: BUYER.toLowerCase(),
                    },
                  ]}
                  name="company_type"
                  value={formikProps.values.company_type}
                  keyValue="name"
                  placeholder="Select Lead Type"
                  optionsListMinHeight={650}
                  {...formikProps}
                />
              </Grid>

              <Grid item xs={12} margin="auto">
                <Grid
                  container
                  justifyContent="center"
                  alignContent="center"
                  spacing={2}
                >
                  <Grid item xs={12} lg={6}>
                    <Input
                      name="name"
                      label={`${t("company-name")}`}
                      placeholder={`${t("company-name")}`}
                      fullWidth
                      style={{ width: "100%" }}
                      disabled={values.company_type === ""}
                      type="text"
                      required
                      {...formikProps}
                    />
                  </Grid>

                  <Grid item xs={12} lg={6}>
                    <Input
                      name="reg_no"
                      required
                      fullWidth
                      style={{ width: "100%" }}
                      label={`${t("company-registration-number")}`}
                      placeholder={`${t("company-registration-number")}`}
                      disabled={values.company_type === ""}
                      {...formikProps}
                    />
                  </Grid>
                </Grid>
              </Grid>

              <Grid item xs={12} margin="auto">
                <Grid
                  container
                  justifyContent="center"
                  alignContent="center"
                  spacing={2}
                >
                  <Grid item xs={12}>
                    <Typography
                      variant="h4"
                      sx={{
                        fontWeight: "bolder",
                      }}
                    >
                      {t("company-registered-address")}
                    </Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <Input
                      name="address_line1"
                      required
                      fullWidth
                      label={`${t("address")} 1`}
                      placeholder={`${t("street")} 1`}
                      style={{ width: "100%" }}
                      disabled={values.company_type === ""}
                      {...formikProps}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Input
                      name="address_line2"
                      fullWidth
                      label={`${t("address")} 2`}
                      disabled={values.company_type === ""}
                      placeholder={`${t("street")} 2`}
                      style={{ width: "100%" }}
                      {...formikProps}
                    />
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12} margin="auto">
                <Grid
                  container
                  justifyContent="center"
                  alignContent="center"
                  spacing={2}
                >
                  <Grid item lg={6} xs={12}>
                    <DateInput
                      name="incorporation_date"
                      label={`${t("company-incorporation-date")}`}
                      required
                      value={
                        formikProps.values.incorporation_date !== undefined &&
                        formikProps.values.incorporation_date !== null
                          ? new Date(formikProps.values.incorporation_date)
                          : undefined
                      }
                      error={formikProps.errors.incorporation_date as string}
                      onChangeCommitted={(date) => {
                        formikProps.setFieldTouched("incorporation_date", true);
                        formikProps.validateField("incorporation_date");
                      }}
                      disabled={values.company_type === ""}
                      {...formikProps}
                    />
                  </Grid>
                  <Grid item lg={6} xs={12}>
                    <Input
                      name="city"
                      label={`${t("city")}`}
                      required
                      placeholder={`${t("city")}`}
                      disabled={values.company_type === ""}
                      style={{ width: "100%" }}
                    />
                  </Grid>
                  <Grid item lg={6} xs={12}>
                    <Drop
                      label={`${t("country")}`}
                      multilevel={false}
                      data={countries as Country[]}
                      name="country"
                      value={formikProps.values.country}
                      keyValue="name"
                      placeholder={`${t("select-country")}`}
                      disabled={values.company_type === ""}
                      setValue={setter}
                      required
                      {...formikProps}
                    />
                  </Grid>
                  <Grid item lg={6} xs={12}>
                    <Input
                      name="postcode"
                      required
                      label={`${t("postcode")}`}
                      placeholder={`${t("postcode")}`}
                      style={{ width: "100%" }}
                      disabled={values.company_type === ""}
                      {...formikProps}
                    />
                  </Grid>
                  {values.company_type === "" ? (
                    <></>
                  ) : (
                    <>
                      <Grid item xs={12}>
                        <Divider />
                      </Grid>
                      <ShippingAddress isDisabled={false} />
                    </>
                  )}
                </Grid>
              </Grid>

              <Grid item xs={12} lg={6}>
                <Grid container>
                  {industries && industries?.length && (
                    <Grid item xs={12}>
                      <>
                        {isFetchingIndustries && <SkeletonLoad bars={1} />}
                        {!isFetchingIndustries && (
                          <Drop
                            label="Industry"
                            required
                            name="industry"
                            style={{ width: "100%" }}
                            keyValue="name"
                            data={industries}
                            disabled={values.company_type === ""}
                            placeholder="Select Industry"
                            {...formikProps}
                          />
                        )}
                      </>
                    </Grid>
                  )}
                </Grid>
              </Grid>
              <Grid item xs={12} lg={6}>
                <Grid container>
                  <Grid item xs={12}>
                    <Goods
                      industry={formikProps.values.industry ?? ""}
                      disabled={values.company_type === ""}
                      formikProps={formikProps}
                    />
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12} lg={6}>
                {accountingSoftwares && accountingSoftwares?.length && (
                  <>
                    {isFetchingAccountingSoftware && <SkeletonLoad bars={1} />}
                    {!isFetchingAccountingSoftware && (
                      <Drop
                        label="Company Accounting Software"
                        required
                        name="accounting_software"
                        style={{ width: "100%" }}
                        keyValue="name"
                        data={accountingSoftwares}
                        setValue={setterAccountingSoftware}
                        placeholder="Select Accouting Software"
                        disabled={values.company_type === ""}
                        {...formikProps}
                      />
                    )}
                  </>
                )}
              </Grid>
              {formikProps.values.accounting_software === "Others" && (
                <Grid item lg={6} xs={12}>
                  <Input
                    name="other_accounting_software"
                    required
                    label="Please specify your accounting software"
                    disabled={values.company_type === ""}
                    style={{ width: "100%" }}
                  />
                </Grid>
              )}

              <Grid item xs={12} lg={6}>
                <Input
                  name="annual_revenue"
                  label="Annual Revenue (in USD equivalent)"
                  required
                  type="money"
                  textfieldProps={{
                    InputProps: {
                      startAdornment: (
                        <InputAdornment position="start">USD</InputAdornment>
                      ),
                    },
                  }}
                  style={{ width: "100%" }}
                  disabled={values.company_type === ""}
                  {...formikProps}
                />
              </Grid>
              <Grid item xs={12} lg={6}>
                <Drop
                  label="No. of Employees"
                  required
                  name="number_of_employees"
                  style={{ width: "100%" }}
                  keyValue="name"
                  disabled={values.company_type === ""}
                  value={formikProps.values.number_of_employees}
                  data={[
                    {
                      name: "Self-employed",
                      value: "Self-employed",
                    },
                    {
                      name: "1-10 employees",
                      value: "1-10 employees",
                    },
                    {
                      name: "11-50 employees",
                      value: "11-50 employees",
                    },
                    {
                      name: "51-200 employees",
                      value: "51-200 employees",
                    },
                    {
                      name: "201-500 employees",
                      value: "201-500 employees",
                    },
                    {
                      name: "501-1000 employees",
                      value: "501-1000 employees",
                    },
                    {
                      name: "1001-5000 employees",
                      value: "1001-5000 employees",
                    },
                    {
                      name: "5001-10,000 employees",
                      value: "5001-10,000 employees",
                    },
                    {
                      name: "10,000+ employees",
                      value: "10,000+ employees",
                    },
                  ]}
                  setValue={setterNumberOfEmployees}
                  placeholder="Select Range"
                  {...formikProps}
                />
              </Grid>

              <Grid item xs={10} lg={3} margin={0} ml="auto">
                <LoadingButton
                  fullWidth
                  onClick={() => createLeadActions()}
                  variant="contained"
                  type="button"
                  loading={isLoadingCreateLead}
                  disabled={!isObjectEmpty(errors) || !dirty}
                >
                  {`${t("submit")}`}
                </LoadingButton>
              </Grid>
            </Grid>
          </Form>
        );
      }}
    </Formik>
  );
};
export default CreateLead;

import { Grid, Stack, Typography } from "@mui/material";
import MainCard from "components/Common/Cards/MainCard";
import LabelViewOnly from "components/Common/FormFields/LabelViewOnly";
import Layout from "components/Common/Layout";
import VariableDate from "components/Common/VariableDate";
import VariableDropdown from "components/Common/VariableDropdown";
import VariableInput from "components/Common/VariableInput";
import { Formik, useFormik } from "formik";
import { useParams } from "react-router-dom";
import * as Yup from "yup";
import mocks from "../mockData";

interface TermSheet {
  id: string;
  date: string;
  product: string;
  subProduct: string;
  specialCase: string;
  features: string;
  advancedAmount: number;
  companyName: string;
  countryOfIncorporation: string;
  tradedGoodsDescription: string;
  preQualifiedBuyers: string;
  expectedTradeAmount: number;
  currency: string;
  annualisedDiscountRate: number;
  expectedTenor: string;
  facilityFee: number;
  processingFee: number;
  facilitySetupAndPlatformMaintenanceFee: number;
  benchmarkRate: number;
}
const TermSheetForm = () => {
  const id = useParams<{ id: string }>()?.id;
  const termsheets = mocks.termsheetsMockData;
  const termsheet = termsheets.find((inv) => inv.id === id) as TermSheet;

  const formik = useFormik({
    initialValues: termsheet,
    validationSchema: Yup.object({
      date: Yup.date().required("Required"),
      product: Yup.string().required("Required"),
      subProduct: Yup.string().required("Required"),
      specialCase: Yup.string().required("Required"),
      features: Yup.string().required("Required"),
      advancedAmount: Yup.number().required("Required"),
      companyName: Yup.string().required("Required"),
      countryOfIncorporation: Yup.string().required("Required"),
      tradedGoodsDescription: Yup.string().required("Required"),
      preQualifiedBuyers: Yup.string().required("Required"),
      expectedTradeAmount: Yup.number().required("Required"),
      currency: Yup.string().required("Required"),
      annualisedDiscountRate: Yup.number().required("Required"),
      expectedTenor: Yup.string().required("Required"),
      facilityFee: Yup.number().required("Required"),
      processingFee: Yup.number().required("Required"),
      facilitySetupAndPlatformMaintenanceFee: Yup.number().required("Required"),
      benchmarkRate: Yup.number().required("Required"),
    }),
    onSubmit: (values) => {
      console.log(JSON.stringify(values, null, 2));
    },
  });

  return (
    <Formik onSubmit={() => {}} {...formik}>
      <Layout
        title="Termsheet"
        textHeadingOptions={{
          fontWeight: "bolder",
          level: 2,
          fontSize: "1.5em",
        }}
        headerConfig={{
          left: 12,
          mid: 0,
          right: 0,
        }}
        mode="default"
        alignItems="center"
      >
        <Stack spacing={3}>
          {/* Intro */}
          <MainCard
            content
            children={
              <Stack spacing={2}>
                <Stack direction="row" spacing={2} alignItems="center">
                  <Typography>Date</Typography>
                  <VariableDate name="date" />
                </Stack>
                <Stack
                  direction="row"
                  spacing={2}
                  flexWrap="wrap"
                  alignItems="center"
                >
                  Incomlend is delighted to offer
                  <VariableDropdown name="offer" keyValue="" data={[]} /> a
                  preliminary indicative proposal for{" "}
                  <VariableDropdown name="proposal" keyValue="" data={[]} />
                  . This digital solution allows for the easy submission of
                  invoices related to transactions via Incomlend Pte Ltd ’s
                  online platform. Please be aware that the availability of
                  these facilities is contingent upon successful internal risk
                  assessments, customer due diligence, and the acquisition of
                  other necessary approvals.
                </Stack>
              </Stack>
            }
          />
          {/* Product Details */}
          <MainCard
            title="Product Details"
            content
            headerSx={{ padding: 2 }}
            contentSX={{ padding: 1 }}
            children={
              <Stack spacing={2}>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <Stack spacing={2} direction="row">
                      <Stack
                        spacing={2}
                        direction="row"
                        alignItems="center"
                      >
                        <Typography>Product</Typography>
                        <VariableDropdown
                          name="product"
                          keyValue=""
                          data={[]}
                        />
                      </Stack>
                      <Stack
                        spacing={2}
                        direction="row"
                        alignItems="center"
                      >
                        <Typography>Sub Product</Typography>
                        <VariableDropdown
                          name="subProduct"
                          keyValue=""
                          data={[]}
                        />
                      </Stack>
                    </Stack>
                  </Grid>
                  <Grid item xs={12}>
                    <Stack spacing={2} direction="row" alignItems="center">
                      <Typography>Special Case</Typography>
                      <VariableDropdown
                        name="specialCase"
                        keyValue=""
                        data={[]}
                      />
                    </Stack>
                  </Grid>
                  <Grid item xs={12}>
                    <Stack spacing={2} direction="row" alignItems="center">
                      <Typography>Features</Typography>
                      <VariableInput
                        name="features"
                        multiline
                        label={undefined}
                      />
                    </Stack>
                  </Grid>
                  <Grid item xs={12}>
                    <Stack spacing={2} direction="row" alignItems="center">
                      <Typography>Advanced Amount</Typography>
                      <VariableInput
                        name="advancedAmount"
                        multiline
                        label={undefined}
                      />
                    </Stack>
                  </Grid>
                </Grid>
              </Stack>
            }
          />

          {/* Client Details */}
          <MainCard
            title="Client Details"
            content
            headerSx={{ padding: 2 }}
            contentSX={{ padding: 1 }}
            children={
              <Stack spacing={2}>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <Stack spacing={2} direction="row">
                      <Stack
                        spacing={2}
                        direction="row"
                        alignItems="center"
                      >
                        <Typography>Company Name</Typography>
                        <VariableInput name="companyName" label={undefined} />
                      </Stack>
                      <Stack
                        spacing={2}
                        direction="row"
                        alignItems="center"
                      >
                        <Typography>Country of Incorporation</Typography>
                        <VariableDropdown
                          name="countryOfIncorporation"
                          keyValue=""
                          data={[]}
                        />
                      </Stack>
                    </Stack>
                  </Grid>
                  <Grid item xs={12}>
                    <Stack spacing={2} direction="row" alignItems="center">
                      <Typography>Traded Goods Description</Typography>
                      <VariableInput
                        name="tradedGoodsDescription"
                        multiline
                        label={undefined}
                      />
                    </Stack>
                  </Grid>
                </Grid>
              </Stack>
            }
          />

          {/* Costs */}
          <MainCard
            title="Costs"
            content
            headerSx={{ padding: 2 }}
            contentSX={{ padding: 1 }}
            children={
              <Stack spacing={2}>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <Stack spacing={2} direction="row" alignItems="center">
                      <Stack
                        spacing={2}
                        direction="row"
                        alignItems="center"
                      >
                        <Typography>Pre-Qualified Buyers</Typography>
                        <VariableDropdown
                          name="preQualifiedBuyers"
                          keyValue=""
                          data={[]}
                        />
                      </Stack>
                      <Stack
                        spacing={2}
                        direction="row"
                        alignItems="center"
                      >
                        <Typography>Expected Trade Amount</Typography>
                        <VariableDropdown
                          name="expectedTradeAmount"
                          keyValue=""
                          data={[]}
                        />
                      </Stack>
                    </Stack>
                  </Grid>

                  <Grid item xs={12}>
                    <Stack spacing={2} direction="row" alignItems="center">
                      <Stack
                        spacing={2}
                        direction="row"
                        alignItems="center"
                      >
                        <Typography>Currency</Typography>
                        <VariableDropdown
                          name="currency"
                          keyValue=""
                          data={[]}
                        />
                      </Stack>
                      <Stack
                        spacing={2}
                        direction="row"
                        alignItems="center"
                      >
                        <Typography>Annualised Discount Rate</Typography>
                        <VariableDropdown
                          name="annualisedDiscountRate"
                          keyValue=""
                          data={[]}
                        />
                      </Stack>
                    </Stack>
                  </Grid>

                  <Grid item xs={12}>
                    <Stack spacing={2} direction="row" alignItems="center">
                      <Stack
                        spacing={2}
                        direction="row"
                        alignItems="center"
                      >
                        <Typography>Expected Tenor</Typography>
                        <VariableDropdown
                          name="expectedTenor"
                          keyValue=""
                          data={[]}
                        />
                      </Stack>
                    </Stack>
                  </Grid>
                  <Grid item xs={12}>
                    <Typography fontStyle="italic">
                      *The rate offered is tentative, based on an initial
                      assessment of credit risk. Depending on the findings of
                      the risk evaluation by Incomlend Pte Ltd during the
                      onboarding stage, the final rate may be adjusted up or
                      down by a maximum of 0.5%.
                    </Typography>
                  </Grid>

                  <Grid item xs={12}>
                    <Stack spacing={2} direction="row" alignItems="center">
                      <Stack
                        spacing={2}
                        direction="row"
                        alignItems="center"
                      >
                        <LabelViewOnly
                          label="Facility Fee"
                          helpText="something"
                        />
                        <VariableDropdown
                          name="facilityFee"
                          keyValue=""
                          data={[]}
                        />
                      </Stack>
                      <Stack
                        spacing={2}
                        direction="row"
                        alignItems="center"
                      >
                        <LabelViewOnly
                          label="Processing Fee"
                          helpText="something"
                        />
                        <VariableDropdown
                          name="processingFee"
                          keyValue=""
                          data={[]}
                        />
                      </Stack>
                    </Stack>
                  </Grid>

                  <Grid item xs={12}>
                    <Stack spacing={2} direction="row" alignItems="center">
                      <LabelViewOnly
                        label="Facility Setup and Platform Maintenance Fee"
                        helpText="something"
                      />
                      <VariableInput
                        multiline
                        name="facilitySetupAndPlatformMaintenanceFee"
                        label={undefined}
                      />
                    </Stack>
                  </Grid>

                  <Grid item xs={12}>
                    <Stack spacing={2} direction="row" alignItems="center">
                      <LabelViewOnly
                        label="Benchmark Rate (Non-Included in Discount Rate)"
                        helpText="something"
                      />
                      <VariableInput
                        multiline
                        name="benchmarkRate"
                        label={undefined}
                      />
                    </Stack>
                  </Grid>
                </Grid>
              </Stack>
            }
          />
          {/* Disclaimer */}
          <Stack spacing={2}>
            <Typography fontWeight="bold" variant="h3">
              Disclaimer
            </Typography>
            <Typography>
              Overview and Confidentiality Agreement of Indicative Financing
              Terms by Incomlend Pte Ltd
            </Typography>
            <Typography>
              This indicative term sheet (“Term Sheet”) summarizes the principal
              terms, for negotiation purposes only, with respect to a potential
              financing arrangement by Incomlend Pte. Ltd. Save as otherwise
              specifically provided, nothing herein creates any legally binding
              obligation on the part of any party, nor shall any legally binding
              obligations arise unless and until the parties have executed
              definitive written agreements and obtained all requisite
              governmental, corporate, management and legal approval.
            </Typography>
            <Typography>
              NDA: By signing this agreement, you agree to keep all sensitive
              information (including rates, fees payable, and other information
              acquired in consequence of this Term Sheet) confidential except
              where (a) you are entitled or bound to disclose such information
              under compulsion of law; or (b) it is reasonably necessary for the
              performance of the transaction envisaged under this Term Sheet.
              You will maintain third-party confidentiality. Incomlend can seek
              extra relief for any breach or threatened breach of
              confidentiality standards without proving loss or damage.
            </Typography>
          </Stack>
        </Stack>
      </Layout>
    </Formik>
  );
};
export default TermSheetForm;

import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import {
  Card,
  CardContent,
  CardProps,
  Checkbox,
  Grid,
  GridTypeMap,
  Typography,
  TypographyProps,
} from "@mui/material";
import useIsMobile from "hooks/useIsMobile";
import React, { useState } from "react";
import styles from "../../addcompany.module.scss";
import Icons from "./Cards";

interface Image {
  src: string;
  height?: string;
  alt?: string;
}
interface ICard {
  name: string;
  onClick?: () => void;
  checked?: boolean;
  image?: Image;
  backgroundColor?: React.CSSProperties["backgroundColor"];
  bottomColorBand?: React.CSSProperties["borderBottom"];
  textColor?: React.CSSProperties["color"];
  icon?: any;
}
export interface SelectorCardProps extends CardProps {
  /**Selector Card Data*/
  cards: ICard[];
  value: string;
  options: Array<string>;
  /**Styling for all cards*/
  style?: React.CSSProperties;
  /**Text color for all cards*/

  /**Text active color for all cards*/
  textActiveColor?: React.CSSProperties["color"];
  /**Checkmark fill color for all cards*/
  checkmarkFillColor?: React.CSSProperties["color"];
  /**Selector name for formfield */
  name: string;
  itemSpacing?: GridTypeMap["props"]["spacing"];
  headingProps?: TypographyProps;
  /**
   * Used in conditions like form-within-form, where the field value setter is external.
   */
  setFieldValue: (field: string, value: any, shouldValidate?: any) => void;
}

const SelectorCard = (props: SelectorCardProps) => {
  const {
    cards,
    style,

    textActiveColor = "#ffffff",
    checkmarkFillColor,
    setFieldValue,
    name,
    value,
    itemSpacing = 3,
    headingProps,
    options,
    ...cardProps
  } = props;
  const [cardName, setCardName] = useState(value);

  const isMobile = useIsMobile();
  return (
    <Grid container spacing={itemSpacing} justifyContent="center">
      {cards &&
        cards.map((card, i) => {
          const checked = cardName === card.name;

          return (
            <Grid item key={card.name}>
              <Card
                {...cardProps}
                onClick={() => [
                  setCardName(card.name),
                  setFieldValue(name, card.name, true),
                ]}
                className={`${styles.card} ${checked ? styles.active : ""}`}
                style={{
                  ...style,
                  borderRadius: "10px",
                  backgroundImage: `url(${card?.image?.src})`,
                  backgroundRepeat: "no-repeat",
                  backgroundSize: `${card?.image?.src ? "100% 100%" : "cover"}`,
                  backgroundPosition: "center",
                  background: checked
                    ? `linear-gradient(to top, ${(
                        card?.bottomColorBand as string
                      )
                        .split(" ")
                        .at(2)} 100%, ${(card?.bottomColorBand as string)
                        .split(" ")
                        .at(2)} 0.1%, white 0%)`
                    : `linear-gradient(to top, ${(
                        card?.bottomColorBand as string
                      )
                        .split(" ")
                        .at(2)} 0%, white 0.1%, white 100%)`,

                  transition: "all .5s ease-in-out",
                  borderBottom: card?.bottomColorBand,
                }}
              >
                <CardContent sx={{ p: 0 }}>
                  <Grid container justifyContent="center" margin="auto">
                    <Grid
                      item
                      xs={12}
                      alignItems="center"
                      justifyContent="flex-end"
                      display="flex"
                    >
                      <Checkbox
                        checked={Boolean(checked)}
                        className={styles.roundedCheck}
                        sx={{ padding: isMobile ? 0 : 1 }}
                        icon={
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="16"
                            height="16"
                            viewBox="0 0 512 512"
                          >
                            <path d="" />
                          </svg>
                        }
                        checkedIcon={
                          <CheckCircleIcon
                            style={{
                              padding: isMobile ? 0 : 1,
                              width: isMobile ? 18 : 24,
                            }}
                            sx={{
                              fill: checked
                                ? textActiveColor
                                : checkmarkFillColor,
                            }}
                          />
                        }
                      />
                    </Grid>
                    <Grid item xs={12} textAlign="center">
                      {i ? (
                        <Icons.BuyerIcon
                          active={checked as boolean}
                          activeColor={textActiveColor}
                          size={isMobile ? "40" : "52.34"}
                        />
                      ) : (
                        <Icons.SupplierIcon
                          active={checked as boolean}
                          activeColor={textActiveColor}
                          size={isMobile ? "40" : "52.34"}
                        />
                      )}
                    </Grid>

                    <Grid item xs={12}>
                      <Typography
                        variant="h3"
                        {...headingProps}
                        textAlign="center"
                        fontWeight="bold"
                        color={checked ? textActiveColor : card.textColor}
                      >
                        {card.name}
                      </Typography>
                    </Grid>
                  </Grid>
                </CardContent>
              </Card>
            </Grid>
          );
        })}
    </Grid>
  );
};

export default SelectorCard;

import { Box, Grid, Slide } from "@mui/material";
import { useGetCurrentUserQuery } from "api/api-users-v2";
import { DEFAULT_ROWS, INCOMLEND_INTERNAL_LINK, TERMSHEETS_LINK } from "codes";
import DataTablev2 from "components/Common/DataTablev2";
import Layout from "components/Common/Layout";
import NoData from "components/Common/NoData";
import history from "helpers/history";
import useWidth from "helpers/useWidth";
import useIsMobile from "hooks/useIsMobile";
import { useRef } from "react";
import mocks from "./mockData";

const Termsheets = () => {
  const isMobile = useIsMobile();
  const termsheets = mocks.termsheetsMockData;
  const ref = useRef<HTMLDivElement>(null);
  const width = ref ? useWidth(ref) : 1000;

  const { data: curr_user, isFetching: isFetchingUsers } =
    useGetCurrentUserQuery();

  const headerLength = Object.keys(termsheets?.[0] ?? {})?.length;
  const defaultColumnWidth = width ? (width / headerLength) * 1.6 : 200;
  const settings = curr_user?.settings ?? {};
  const rowsPerPage = settings?.rowsPerPage ?? DEFAULT_ROWS;
  // useEffect(() => {}, [isSuccess]);
  return (
    <Slide in direction="left">
      <Box flexGrow={1}>
        <Layout
          title="Termsheets"
          textHeadingOptions={{
            fontWeight: "bolder",
            level: 2,
            fontSize: isMobile ? "1.2em" : "1.5em",
          }}
          headerConfig={{
            // syncAction: refetch,
            left: 12,
            mid: 0,
            right: 0,
          }}
          mode="default"
          alignItems="center"
        >
          <Grid item xs={12} ref={ref}>
            <Grid container spacing={1}>
              {/* {isFetching && <SkeletonLoad bars={10} />} */}
              {/* {!isFetching && balances?.length ? ( */}
              {termsheets ? (
                <DataTablev2
                  rowsPerPage={rowsPerPage}
                  defaultColumnWidth={defaultColumnWidth}
                  data={termsheets}
                  onRowClicked={(row) =>
                    history.push(
                      `${INCOMLEND_INTERNAL_LINK}${TERMSHEETS_LINK}/${row.id}`
                    )
                  }
                />
              ) : (
                <NoData text="No transactions found" sx={{ margin: "auto" }} />
              )}
            </Grid>
          </Grid>
        </Layout>
      </Box>
    </Slide>
  );
};
export default Termsheets;

import * as Sentry from "@sentry/react";
import { useTranslationsQuery } from "api/api-misc";
import {
  useFetchIncomlendPermissionsQuery,
  useFetchUserPermissionsQuery,
  useGetCurrentUserQuery,
} from "api/api-users-v2";
import { INCOMLEND_INTERNAL } from "codes";
import Loader from "components/Common/Loader";
import SkeletonLoad from "components/Common/SkeletonLoad";
import Dashboard from "components/Dashboard";
import NewVersionPrompt from "components/Dashboard/NewVersionPrompt";
import Floater from "components/Floater";
import auth from "helpers/auth";
import offlineI18n from "helpers/i18n";
import onlineI18n from "helpers/i18n-online";
import { roleRedirect } from "helpers/redirect";
import useIsMobile from "hooks/useIsMobile";
import { Suspense, useEffect, useState } from "react";
import { I18nextProvider } from "react-i18next";
import { Outlet } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "redux/hooks";
import {
  setAppPermission,
  setAppPermissions,
  setRole,
  setUserPermission,
} from "redux/reducers/appStatesReducer";
import Error from "./pages/error";

const Incomlend = () => {
  auth();
  const dispatch = useAppDispatch();
  const [i18n, seti18n] = useState(offlineI18n);
  const { data: user } = useGetCurrentUserQuery();
  const { data: translations, isSuccess } = useTranslationsQuery();

  const APP_PERMISSION = useAppSelector((state) => state.appState.permission);
  const USER_ROLE = useAppSelector((state) => state.appState.role);

  const url = new URL(window.location.href);
  const language = new URLSearchParams(url.search)?.get("lang");
  if (language) i18n.changeLanguage(language);

  const isMobile = useIsMobile();
  useEffect(() => {
    if (isSuccess && translations?.data) seti18n(onlineI18n(translations.data));
  }, [isSuccess, translations]);
  const shouldShowFloater = APP_PERMISSION !== INCOMLEND_INTERNAL;

  const {
    data: userPermissions,
    isFetching: isFetchingUserPermissions,
    isUninitialized: isUserPermissionUninitialized,
  } = useFetchUserPermissionsQuery(user?.id ?? "", {
    skip: !Boolean(user),
  });
  const {
    data: incomlendPermissions,
    isFetching: isFetchingIncomlendPermissions,
    isUninitialized: isIncomlendPermissionUninitialized,
  } = useFetchIncomlendPermissionsQuery(user?.id ?? "", {
    skip: !Boolean(user),
  });

  useEffect(() => {
    if (!isFetchingUserPermissions && !isFetchingIncomlendPermissions) {
      //Initial Role creation
      if (USER_ROLE?.id === "" && incomlendPermissions?.groups?.[0]) {
        dispatch(
          setRole({
            id: INCOMLEND_INTERNAL,
            name: INCOMLEND_INTERNAL,
            permission: incomlendPermissions.permissions,
            role: INCOMLEND_INTERNAL,
          })
        );
        if (!APP_PERMISSION) dispatch(setAppPermission(INCOMLEND_INTERNAL));
        dispatch(setAppPermissions(incomlendPermissions?.groups));
        dispatch(setUserPermission(incomlendPermissions?.permissions));
      } else if (USER_ROLE?.id === "" && userPermissions?.[0]) {
        dispatch(
          setRole({
            ...userPermissions?.[0],
            role: userPermissions?.[0].director ? "Director" : "Non-Director",
          })
        );
        if (!APP_PERMISSION)
          dispatch(setAppPermission(userPermissions?.[0]?.id));
        dispatch(setAppPermissions(userPermissions?.[0].permission));
        dispatch(setUserPermission(userPermissions?.[0].permission));
      }
      //Initial Re-redirection
      if (isUserPermissionUninitialized && isIncomlendPermissionUninitialized)
        roleRedirect(APP_PERMISSION);
    }
  }, [
    APP_PERMISSION,
    USER_ROLE,
    isFetchingUserPermissions,
    isFetchingIncomlendPermissions,
  ]);

  return (
    <Sentry.ErrorBoundary
      fallback={({ error, componentStack, resetError }) => (
        <Error
          error={error as Error}
          componentStack={componentStack}
          resetError={resetError}
          scope="Dashboard routes"
        />
      )}
      beforeCapture={(scope) => {
        scope.setTag("location", "Dashboard routes");
      }}
    >
      <I18nextProvider i18n={i18n}>
        {/* <AppLogout> */}
        <Dashboard>
          <Suspense fallback={<Loader open />}>
            {isFetchingUserPermissions && isFetchingIncomlendPermissions && (
              <SkeletonLoad bars={10} />
            )}
            {!isFetchingUserPermissions && !isFetchingIncomlendPermissions && (
              <Outlet />
            )}
          </Suspense>
          {!isMobile && <Floater shouldShowFloater={shouldShowFloater} />}
          <NewVersionPrompt />
        </Dashboard>
        {/* </AppLogout> */}
      </I18nextProvider>
    </Sentry.ErrorBoundary>
  );
};

export default Incomlend;

import { Box, Grid } from "@mui/material";
import { useGetDailyInvoiceStatsQuery } from "api/api-operations";
import NoData from "components/Common/NoData";
import SkeletonLoad from "components/Common/SkeletonLoad";
import Highcharts, { type SeriesOptionsType } from "highcharts";
import HighchartsReact from "highcharts-react-official";

// Define types for clarity
type InvoiceStats = {
  date: string;
  financed_per_buyer: { buyer: string; amount: string }[];
};

// Function to sort invoice stats by date
const sortInvoiceStatsByDate = (stats: InvoiceStats[] | undefined) => {
  return stats
    ? stats.slice().sort((a, b) => {
        const dateA = new Date(a.date);
        const dateB = new Date(b.date);
        return dateA.getTime() - dateB.getTime();
      })
    : [];
};

const transformStatsToSeriesData = (stats: InvoiceStats[]) => {
  const seriesData: SeriesOptionsType[] = [];
  const buyerNamesMap: { [buyerName: string]: boolean } = {};

  // Populate series data with actual amounts
  stats.forEach((dayData, index) => {
    dayData.financed_per_buyer.forEach((item) => {
      const buyerName = item.buyer;

      // Check if the buyer name has already been added
      if (!buyerNamesMap[buyerName]) {
        buyerNamesMap[buyerName] = true;

        // Add the buyer name and initialize its data
        seriesData.push({
          name: buyerName,
          data: new Array(stats.length).fill(null),
          type: "bar",
        });
      }

      // Find the index of the buyer name in the series data
      const seriesIndex = seriesData.findIndex(
        (series) => series.name === buyerName
      );

      // Update the data with the actual amount
      (seriesData[seriesIndex] as { name: string; data: number[] }).data[
        index
      ] = Number(item.amount) || 0;
    });
  });

  return seriesData;
};

function DailyInvoiceChart() {
  const { data: dailyInvoiceStats, isFetching } =
    useGetDailyInvoiceStatsQuery(null);

  if (isFetching && !dailyInvoiceStats) {
    return <SkeletonLoad bars={10} />;
  }

  // Sort invoice stats by date
  const sortedDailyInvoiceStats = sortInvoiceStatsByDate(
    dailyInvoiceStats?.map((item) => ({
      ...item,
      financed_per_buyer: item.financed_per_buyer.map((buyer) => ({
        ...buyer,
        amount: buyer.amount.toString(),
      })),
    }))
  );

  // Transform sorted stats into series data
  const seriesData = transformStatsToSeriesData(sortedDailyInvoiceStats);

  // Define Highcharts options
  const options: Highcharts.Options = {
    chart: {
      type: "bar",
    },
    title: {
      text: "Invoice Financed by Buyer (USD $)",
    },
    legend: {
      reversed: true,
    },
    xAxis: {
      categories: sortedDailyInvoiceStats.map((e) => e?.date),
    },
    yAxis: {
      title: {
        text: "Amount (USD $)",
      },
    },
    plotOptions: {
      series: {
        stacking: "normal",
        dataLabels: {
          enabled: true,
        },
      },
    },
    series: seriesData,
  };

  return (
    <Box sx={{ display: "flex" }} flexGrow={1}>
      {isFetching && <SkeletonLoad bars={10} />}
      {!isFetching && !dailyInvoiceStats && (
        <NoData text="No daily invoice data to be displayed" />
      )}
      {!isFetching && dailyInvoiceStats && (
        <Grid container>
          <Grid item xs={12}>
            {sortedDailyInvoiceStats.length === 0 && (
              <NoData text="No daily invoice data to be displayed" />
            )}
            {sortedDailyInvoiceStats.length > 0 && (
              <HighchartsReact
                highcharts={Highcharts}
                options={options}
                allowChartUpdate
              />
            )}
          </Grid>
        </Grid>
      )}
    </Box>
  );
}

export default DailyInvoiceChart;

import { Grid, Typography } from "@mui/material";
import { currencyFormatter } from "helpers/currencyFormatter";
import moment from "moment";
import LabelOnly from "./LabelOnly";

interface SalesforceOpportunity {
  currency?: string;
  name?: string;
  leadName?: string;
  leadNameSupplier?: string;
  salesOwner?: string;
  dateTermsheetSent?: string;
  dateTermSheetSignedt?: string;
  insurerName?: string;
  clRequested?: number;
  clProvidedByInsurer?: number;
  creditLimitInsuranceCCY?: string;
  dateAnswerInsurer?: string;
  processStatus?: string;
  createdByName?: string;
  createdDate?: Date;
  link?: string;
}

const SalesforceForm = ({ data }: { data: any }) => {
  const sfOpportunity: SalesforceOpportunity = data;
  return (
    <Grid item xs={12}>
      <Grid container>
        <Grid item xs={12}>
          <Grid container spacing={1} justifyContent="space-between">
            <Grid item lg={6} xs={12}>
              <LabelOnly
                label="Opportunity Name"
                value={
                  <Typography
                    sx={{ textDecoration: "underline", cursor: "pointer" }}
                    onClick={() =>
                      window.open(`${sfOpportunity.link ?? ""}`, "_blank")
                    }
                  >
                    {sfOpportunity.name}
                  </Typography>
                }
              />
            </Grid>
            <Grid item lg={6} xs={12}>
              <LabelOnly
                label="Sales Owner"
                value={sfOpportunity.salesOwner}
              />
            </Grid>
          </Grid>
        </Grid>

        <Grid item xs={12}>
          <Grid container spacing={1} justifyContent="space-between">
            <Grid item lg={6} xs={12}>
              <LabelOnly
                label="Supplier Lead Name"
                value={sfOpportunity.leadNameSupplier}
              />
            </Grid>
            <Grid item lg={6} xs={12}>
              <LabelOnly
                label="Buyer Lead Name"
                value={sfOpportunity.leadName}
              />
            </Grid>
          </Grid>
        </Grid>

        <Grid item xs={12}>
          <Grid container spacing={1} justifyContent="space-between">
            <Grid item lg={6} xs={12}>
              <LabelOnly
                label="Insurer Name"
                value={sfOpportunity.insurerName}
              />
            </Grid>
            <Grid item lg={6} xs={12}>
              <LabelOnly label="Status" value={sfOpportunity.processStatus} />
            </Grid>
          </Grid>
        </Grid>

        <Grid item xs={12}>
          <Grid container spacing={1} justifyContent="space-between">
            <Grid item lg={6} xs={12}>
              <LabelOnly
                label="Credit Limit Requested"
                value={`${sfOpportunity.currency} ${currencyFormatter({
                  amount: sfOpportunity.clRequested ?? 0,
                })}`}
              />
            </Grid>
            <Grid item lg={6} xs={12}>
              <LabelOnly
                label="Credit Limit Provided"
                value={`${sfOpportunity.currency} ${currencyFormatter({
                  amount: sfOpportunity.clProvidedByInsurer ?? 0,
                })}`}
              />
            </Grid>
          </Grid>
        </Grid>

        <Grid item xs={12}>
          <Grid container spacing={1} justifyContent="space-between">
            <Grid item lg={6} xs={12}>
              <LabelOnly
                label="Insurance CCY"
                value={sfOpportunity.creditLimitInsuranceCCY}
              />
            </Grid>
            <Grid item lg={6} xs={12}>
              <LabelOnly
                label="Date Answer Insurance"
                value={
                  sfOpportunity.dateAnswerInsurer
                    ? moment(sfOpportunity.dateAnswerInsurer)
                        .utc()
                        .format("YYYY-MM-DD HH:mm:ss")
                    : "-"
                }
              />
            </Grid>
          </Grid>
        </Grid>

        <Grid item xs={12}>
          <Grid container spacing={1} justifyContent="space-between">
            <Grid item lg={6} xs={12}>
              <LabelOnly
                label="Term Sheet Sent Date"
                value={sfOpportunity.dateTermsheetSent}
              />
            </Grid>
            <Grid item lg={6} xs={12}>
              <LabelOnly
                label="Term Sheet Signed Date"
                value={sfOpportunity.dateTermSheetSignedt}
              />
            </Grid>
          </Grid>
        </Grid>

        <Grid item xs={12}>
          <Grid container spacing={1} justifyContent="space-between">
            <Grid item lg={6} xs={12}>
              <LabelOnly
                label="Created By"
                value={sfOpportunity.createdByName}
              />
            </Grid>
            <Grid item lg={6} xs={12}>
              <LabelOnly
                label="Created When"
                value={
                  sfOpportunity.createdDate
                    ? moment(sfOpportunity.createdDate)
                        .utc()
                        .format("YYYY-MM-DD HH:mm:ss")
                    : "-"
                }
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default SalesforceForm;

import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import React from "react";

interface FXRate {
  currency_from: string;
  currency_to: string;
  rate: number;
  date: string;
}

interface FxTableProps {
  fxRates: FXRate[];
}

const CURRENCY_FLAG = {
  USD: "🇺🇸",
  EUR: "🇪🇺",
  GBP: "🇬🇧",
  INR: "🇮🇳",
  SGD: "🇸🇬",
  AUD: "🇦🇺",
  HKD: "🇭🇰",
};

const FxTable: React.FC<FxTableProps> = ({ fxRates }) => {
  return (
    <TableContainer component={Paper}>
      <Table size="small">
        <TableHead>
          <TableRow>
            <TableCell>From</TableCell>
            <TableCell>To</TableCell>
            <TableCell>Rate</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {fxRates.map((fxRate) => (
            <TableRow key={fxRate.currency_from}>
              <TableCell>
                {
                  CURRENCY_FLAG[
                    fxRate.currency_from as keyof typeof CURRENCY_FLAG
                  ]
                }{" "}
                {fxRate.currency_from}
              </TableCell>
              <TableCell>
                {
                  CURRENCY_FLAG[
                    fxRate.currency_to as keyof typeof CURRENCY_FLAG
                  ]
                }{" "}
                {fxRate.currency_to}
              </TableCell>
              <TableCell>{fxRate.rate}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default FxTable;

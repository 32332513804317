import PrivacyTipIcon from "@mui/icons-material/PrivacyTip";
import VerifiedUserIcon from "@mui/icons-material/VerifiedUser";
import {
  Divider,
  Grid,
  InputAdornment,
  Link,
  Stack,
  Typography,
  useTheme,
} from "@mui/material";
import MainCard from "components/Common/Cards/MainCard";
import { useFormikContext } from "formik";
import { currencyFormatter } from "helpers/currencyFormatter";
import { isMobile } from "react-device-detect";
import type { CLDetails } from "types";

import LoadingButton from "@mui/lab/LoadingButton";
import {
  useEditCreditLimitMutation,
  useGetSalesListQuery,
} from "api/api-origination";
import { BUYER, INCOMLEND_INTERNAL_LINK, LEADS } from "codes";
import LabelViewOnly from "components/Common/FormFields/LabelViewOnly";
import Input from "components/Common/Input";
import SkeletonLoad from "components/Common/SkeletonLoad";
import moment from "moment";
import { PERMISSIONS } from "permissions";
import { useState } from "react";
import { toast } from "react-toastify";
import { useAppSelector } from "redux/hooks";
import styles from "../cl.module.scss";
import LabelOnly from "./LabelOnly";
import SalesDropDown from "./SalesDropDown";

const CLDetailsComponent = () => {
  const formikProps = useFormikContext<CLDetails>();

  const [editCLDetails, { isLoading }] = useEditCreditLimitMutation();
  const APP_PERMISSIONS = useAppSelector(
    (state) => state.appState.user_permission
  );
  const USER_PERMISSIONS = useAppSelector(
    (state) => state.appState.user_permission
  );
  const disabled = !APP_PERMISSIONS.includes(PERMISSIONS.update_credit_limit);

  const { data: salesReps, isFetching } = useGetSalesListQuery(null);
  const { dirty, values } = formikProps;
  const {
    id,
    sf_link,
    currency,
    coface_amount,
    salesLead,
    coface_found,
    requestedBy,
    rating,
    coface_rating_description,
    buyerInternalName,
  } = values;
  const [salesId, setSalesID] = useState(salesLead?.id);

  const setter = (value: string, prev?: string, index?: number) => {
    const newSL = salesReps?.find(
      (rep) => `${rep.first_name} ${rep.last_name}` === value
    );
    setSalesID(newSL?.id ?? "");
  };
  const LINK = `${INCOMLEND_INTERNAL_LINK}${LEADS}/${buyerInternalName}`;
  const theme = useTheme();

  return (
    <Stack spacing={1}>
      <Grid item xs={12}>
        <Stack direction="row" spacing={1} alignItems="center">
          <Grid item>
            <Link
              href={LINK}
              target="_blank"
              fontWeight="bold"
              fontSize="1.5em"
            >
              {formikProps.values.buyerName}
            </Link>
          </Grid>
          <Grid item>
            {formikProps.values.buyerIsIdentified ? (
              <VerifiedUserIcon color="success" />
            ) : (
              <PrivacyTipIcon color="error" />
            )}
          </Grid>
          <Grid item>
            <Typography
              sx={{
                color: formikProps.values.buyerIsIdentified
                  ? theme.palette.success.main
                  : theme.palette.error.main,
                fontWeight: "bold",
              }}
            >
              Source : {formikProps.values.buyerIsIdentified ? "KYC" : "Manual"}
            </Typography>
          </Grid>
        </Stack>
      </Grid>
      <Grid item xs={12}>
        <LabelViewOnly label={`Account Type: ${BUYER}`} />
        <LabelViewOnly label={`Reg.No: ${formikProps.values.buyerRegNo}`} />
        <LabelViewOnly label={`Email: ${formikProps.values.buyerEmail}`} />
        <LabelViewOnly label={`Address: ${formikProps.values.buyerAddress}`} />
        <LabelViewOnly label={`Country: ${formikProps.values.buyerCountry}`} />
        <LabelViewOnly
          label={`Buyer's Annual Revenue: USD ${currencyFormatter({ amount: formikProps.values.buyerAnnualRevenue })}`}
        />
      </Grid>
      <Grid item xs={12}>
        <Divider />
      </Grid>

      <Grid item lg={6} xs={6}>
        {disabled ? (
          <Input
            labelClassName={styles.labelClass}
            value={`${formikProps.values.salesLead.first_name} ${formikProps.values.salesLead.last_name}`}
            name="salesLead.first_name"
            label="Sales Owner"
            placeholder="Sales Owner"
            disabled={formikProps.values.clStatus === "CL_APPROVED"}
            style={{ width: "98%" }}
          />
        ) : (
          <Grid container justifyContent="center" alignContent="center">
            <Grid item xs={12}>
              {isFetching && <SkeletonLoad bars={1} />}
              {!isFetching && salesReps && (
                <SalesDropDown
                  multilevel={false}
                  disabled={formikProps.values.clStatus === "CL_APPROVED"}
                  data={salesReps}
                  name="salesName"
                  value={`${formikProps.values.salesLead.first_name} ${formikProps.values.salesLead.last_name}`}
                  keyValue="name"
                  label="Sales Owner"
                  sx={{ width: "65%" }}
                  placeholder="Select"
                  setValue={setter}
                  {...formikProps}
                />
              )}
            </Grid>
          </Grid>
        )}
      </Grid>

      <Divider />
      <MainCard
        title={
          <Typography
            fontWeight="bold"
            fontSize={isMobile ? "1.1em" : "1.3em"}
            variant="h3"
          >
            Credit Limit
          </Typography>
        }
        footer={
          <>
            <Grid
              item
              lg={2}
              xs={12}
              alignItems="centre"
              justifyContent="flex-end"
              ml="auto"
            >
              <LoadingButton
                fullWidth
                variant="contained"
                loading={isLoading}
                onClick={() => {
                  if (!disabled) {
                    const credit_limit_request_internal_amount = Number(
                      `${formikProps.values.credit_limit_request_internal_amount}`.replaceAll(
                        ",",
                        ""
                      )
                    );
                    salesId &&
                      editCLDetails({
                        id,
                        status: formikProps.values.clStatus,
                        comment: formikProps.values.comment,
                        sales_lead: salesId,
                        credit_limit_request_internal_amount,
                      })
                        .unwrap()
                        .then(() => {
                          toast.success("Credit Limit Details Updated");
                          formikProps.resetForm({ values });
                        })
                        .catch((error) => {
                          const errorFile = error?.message;
                          toast(`Something went wrong : ${errorFile}`, {
                            type: "error",
                          });
                        });
                  }
                }}
                disabled={!dirty}
              >
                Save
              </LoadingButton>
            </Grid>
          </>
        }
      >
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <Grid container>
              <Grid item xs={12} lg={6}>
                <LabelOnly
                  label="Requested Date"
                  value={
                    (formikProps.values.dateRequested as string)
                      ? moment(formikProps.values.dateRequested).format(
                          "DD/MM/YYYY"
                        )
                      : "N/A"
                  }
                />
              </Grid>
              <Grid item xs={12} lg={6}>
                <LabelOnly
                  label="Approval/Rejection Date"
                  value={
                    values.insurerResponse
                      ? moment(formikProps.values.insurerResponse).format(
                          "DD/MM/YYYY"
                        )
                      : "N/A"
                  }
                />
              </Grid>
            </Grid>
          </Grid>
          {/* Insurer */}

          <Grid item xs={12}>
            <Grid container>
              {USER_PERMISSIONS.includes(PERMISSIONS.get_insurer) && (
                <Grid item lg={6} xs={12}>
                  <LabelOnly
                    label="Coface found?"
                    value={coface_found ? "Y" : "N"}
                  />
                </Grid>
              )}
              {USER_PERMISSIONS.includes(PERMISSIONS.get_insurer) && (
                <Grid item lg={6} xs={12}>
                  <LabelOnly
                    label="Requested to Coface By"
                    value={requestedBy ?? "N/A"}
                  />
                </Grid>
              )}
            </Grid>
          </Grid>

          {USER_PERMISSIONS.includes(PERMISSIONS.get_insurer) && (
            <Grid item xs={12}>
              <Grid container justifyContent="space-between">
                <Grid item lg={6} xs={12}>
                  <LabelOnly
                    label="Coface Rating"
                    value={`${rating ? `${rating}/10` : "N/A"}`}
                  />
                </Grid>
                <Grid item lg={6} xs={12}>
                  <LabelOnly
                    label="Coface Rating Description"
                    value={`${
                      coface_rating_description
                        ? `${coface_rating_description}`
                        : "Not Available"
                    }`}
                  />
                </Grid>
              </Grid>
            </Grid>
          )}
          {/* End Insurer */}
          <Grid item xs={12}>
            <Grid container justifyContent="space-between">
              <Grid item lg={6} xs={12}>
                <LabelOnly
                  label="Salesforce Opportunity Link"
                  value={
                    sf_link
                      ? `${import.meta.env.VITE_SF_BASE_URL}${sf_link}`
                      : "N/A"
                  }
                  isLink={Boolean(sf_link)}
                />
              </Grid>
              <Grid item lg={6} xs={12}>
                <LabelOnly
                  label="Requested Amount"
                  value={`${currency ?? "USD"} ${currencyFormatter({
                    amount: formikProps.values.reqAmount,
                  })}`}
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <Grid container spacing={1} justifyContent="space-between">
              {USER_PERMISSIONS.includes(PERMISSIONS.get_insurer) && (
                <Grid item lg={6} xs={12}>
                  <LabelOnly
                    label="Coface Amount"
                    value={`${currency ?? "USD"} ${currencyFormatter({
                      amount: coface_amount ?? 0,
                    })}`}
                  />
                </Grid>
              )}
              <Grid item lg={6} xs={12}>
                <Input
                  labelClassName={styles.labelClass}
                  name="credit_limit_request_internal_amount"
                  label="Approved Amount"
                  disabled={formikProps.values.clStatus === "CL_APPROVED"}
                  placeholder="Approved Amount"
                  textfieldProps={{
                    InputProps: {
                      startAdornment: (
                        <InputAdornment position="start">
                          {currency ?? "USD"}
                        </InputAdornment>
                      ),
                    },
                  }}
                  type="money"
                  style={{ width: "100%" }}
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </MainCard>
    </Stack>
  );
};
export default CLDetailsComponent;

import { Stack } from "@mui/material";
import React from "react";
import InsurerCommunication from "./InsurerCommunication";
import InsurerStatsOverviewData from "./InsurerStatsOverviewData";

const InsurerStatsDashboard: React.FC = () => {
  return (
    <Stack spacing={4}>
      <InsurerStatsOverviewData />
      <InsurerCommunication />
    </Stack>
  );
};

export default InsurerStatsDashboard;

import { Grid } from "@mui/material";
import { useGetMonthlyInvoiceStatsQuery } from "api/api-operations";
import MainCard from "components/Common/Cards/MainCard";
import NoData from "components/Common/NoData";
import SkeletonLoad from "components/Common/SkeletonLoad";
import AvailableInvoiceChart from "components/DashboardInternal/AvailableInvoiceChart";
import DailyInvoiceChart from "components/DashboardInternal/DailyInvoiceChart";
import MonthlyInvoiceChart from "components/DashboardInternal/MonthlyInvoiceChart";

function CommonInternalDashboard() {
  const { data: monthlyInvoiceStats, isFetching } =
    useGetMonthlyInvoiceStatsQuery(null);

  if (isFetching) return <SkeletonLoad bars={10} />;
  if (!monthlyInvoiceStats) return <NoData text="No Data to be displayed" />;
  return (
    <MainCard title="Invoice Statistics">
      <Grid container spacing={3} sx={{ width: "100%", flexGrow: 1 }}>
        <Grid item xs={12} id="monthly-chart-container" lg={6}>
          <MonthlyInvoiceChart />
        </Grid>
        <Grid item xs={12} id="daily-chart-container" lg={6}>
          <DailyInvoiceChart />
        </Grid>
        <Grid item xs={12} id="available-chart-container" lg={6}>
          <AvailableInvoiceChart />
        </Grid>
      </Grid>
    </MainCard>
  );
}

export default CommonInternalDashboard;

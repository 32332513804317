import { PERMISSIONS } from "permissions";
import React from "react";
import { useAppSelector } from "redux/hooks";
import CommonInternalDashboard from "./CommonInternalDashboard";
import InternalDashboardHOC from "./CommonInternalDashboard/InternalDashboardHOC";
import InsurerStatsDashboard from "./InsurerStatsDashboard";
import OriginationMember from "./OriginationMember";
import RiskManagementDashboard from "./RiskManagementDashboard";

const DepartmentScreen: React.FC = () => {
  const USER_PERMISSION = useAppSelector(
    (state) => state.appState.user_permission
  );

  switch (true) {
    case USER_PERMISSION?.includes(PERMISSIONS.restricted_view_stats):
      return <OriginationMember />;
    case USER_PERMISSION?.includes(PERMISSIONS.show_insurer_stats):
      return <InsurerStatsDashboard />;
    case USER_PERMISSION?.includes(PERMISSIONS.show_risk_management):
      return <RiskManagementDashboard />;
    default:
      return <CommonInternalDashboard />;
  }
};

export default InternalDashboardHOC((props) => <DepartmentScreen {...props} />);

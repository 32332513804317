import { Box, Grid } from "@mui/material";
import { useGetPlatformInvoiceStatsQuery } from "api/api-operations";
import NoData from "components/Common/NoData";
import SkeletonLoad from "components/Common/SkeletonLoad";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";

function AvailableInvoiceChart() {
  const { data: platformInvoiceStatsQuery, isFetching } =
    useGetPlatformInvoiceStatsQuery(null);

  // Calculate the total amount in millions
  const totalAmountMillions =
    (platformInvoiceStatsQuery?.reduce(
      (total, invoice) => total + Number(invoice?.amount || 0),
      0
    ) ?? 0) / 1000000;

  const options: Highcharts.Options = {
    chart: {
      type: "pie",
    },
    title: {
      text: `Invoices available on platform - ${totalAmountMillions.toFixed(
        2
      )}M (USD $)`,
    },
    series: [
      {
        type: "pie",
        name: "Amount (USD $)",
        data: platformInvoiceStatsQuery?.map((e) => {
          return {
            name: e?.status ?? "-",
            y: Number(e?.amount),
          };
        }),
      },
    ],
  };

  return (
    <Box sx={{ display: "flex" }} flexGrow={1}>
      {isFetching && <SkeletonLoad bars={10} />}
      {!isFetching && !platformInvoiceStatsQuery && (
        <NoData text="No available invoice data to be displayed" />
      )}
      {!isFetching && platformInvoiceStatsQuery && (
        <Grid container>
          <Grid item xs={12}>
            {platformInvoiceStatsQuery.length === 0 && (
              <NoData text="No available invoice data to be displayed" />
            )}
            {platformInvoiceStatsQuery.length > 0 && (
              <HighchartsReact
                highcharts={Highcharts}
                options={options}
                allowChartUpdate
              />
            )}
          </Grid>
        </Grid>
      )}
    </Box>
  );
}

export default AvailableInvoiceChart;

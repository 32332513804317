import { Box, Grid, Tab, Tabs, Typography, useTheme } from "@mui/material";
import Modal from "components/Common/Modal";
import { Form, Formik } from "formik";

import {
  useAddRemoveGroupPermissionsMutation,
  useGetAllGroupAccessesQuery,
  useGetAllGroupMenusQuery,
  useGetAllGroupsQuery,
} from "api/api-it";
import Layout from "components/Common/Layout";
import AccessTreeGraph from "components/Tech/AccessTreeGraph";
import MenuTreeGraph from "components/Tech/MenuTreeGraph";
import PermissionChanger from "components/Tech/PermissionChanger";
import useIsMobile from "hooks/useIsMobile";
import { useState } from "react";
import { toast } from "react-toastify";
import AddPermission from "./add-permission";

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

interface Account {
  id: string;
  name: string;
  permission: string;
  role: string;
}
interface UserDetails {
  id: string;
  first_name: string;
  last_name: string;
  email: string;
  mobile: string;
  id_no: null | any;
  title: string;
  last_login: string;
  sign_up: string;
  opted_marketing_updates: boolean;
  settings: null | any;
  accounts: Account[];
  departments: string[];
  groups: string[];
}

const ManageUserGroups = () => {
  const initialValues: UserDetails = {
    departments: [],
    email: "",
    first_name: "",
    accounts: [],
    groups: [],
    id: "",
    id_no: null,
    last_login: "",
    last_name: "",
    mobile: "",
    opted_marketing_updates: false,
    settings: null,
    sign_up: "",
    title: "",
  };

  const theme = useTheme();

  const { data: groups, refetch } = useGetAllGroupsQuery(null);
  const { refetch: refetchMenus } = useGetAllGroupMenusQuery(null);
  const { refetch: refetchAccesses } = useGetAllGroupAccessesQuery(null);

  const [addRemoveGroupPermissions] = useAddRemoveGroupPermissionsMutation();
  const [addModal, setAddModal] = useState(false);

  const handleClose = () => setAddModal(false);

  const modalOverlaySx = {
    backgroundColor: "white !important",
    width: "50%",
    alignItems: "center",
    justifyContent: "center",
    borderRadius: "4ch",
    padding: "2ch",
    fontSize: `${theme.typography.fontSize}px !important`,
    fontFamily: `${theme.typography.fontFamily} !important`,
    height: "inherit !important",
    fontWeight: "inherit !important",
  };
  const modalSx = {
    alignItems: "center",
    justifyContent: "center",
    overflow: "hidden",
  };
  const isMobile = useIsMobile();

  function CustomTabPanel(props: TabPanelProps) {
    const { children, value, index, ...other } = props;

    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
      </div>
    );
  }

  function a11yProps(index: number) {
    return {
      id: `simple-tab-${index}`,
      "aria-controls": `simple-tabpanel-${index}`,
    };
  }

  const [value, setValue] = useState(0);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  const GET_SYNC_ACTION = (tab: number) => {
    switch (tab) {
      case 0:
        return refetch;
      case 1:
        return refetchMenus;
      case 2:
        return refetchAccesses;
      default:
        return refetch;
    }
  };

  return (
    <Layout
      title="Permissions"
      textHeadingOptions={{ fontWeight: "bolder", level: 2 }}
      headerConfig={{
        syncAction: GET_SYNC_ACTION(value),
        left: 8,
        mid: 0,
        right: 2,
        xs: {
          left: 12,
          mid: 0,
          right: 12,
        },
        alignItems: "center",
      }}
      primary={
        value === 0
          ? {
              children: "Add Permission",
              onClick: () => setAddModal(true),
              fullWidth: true,
            }
          : undefined
      }
    >
      <Formik
        enableReinitialize
        initialValues={{
          ...initialValues,
          allGroups: groups ?? ({} as Record<string, boolean>),
        }}
        onSubmit={() => {}}
      >
        {(formikProps) => {
          return (
            <Form>
              <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                <Tabs
                  value={value}
                  onChange={handleChange}
                  aria-label="permissions tabs"
                >
                  <Tab label="All" {...a11yProps(0)} />
                  <Tab label="Visual" {...a11yProps(1)} />
                  <Tab label="API Access" {...a11yProps(2)} />
                </Tabs>
              </Box>
              <CustomTabPanel value={value} index={0}>
                <Grid item xs={12}>
                  <Grid container spacing={2}>
                    {formikProps.values.groups &&
                      Object.entries(formikProps.values.allGroups)?.map(
                        ([group_name, value]) => (
                          <PermissionChanger
                            key={group_name}
                            permissions={value}
                            group_name={group_name}
                            onChange={(e, is_active, permissionName) => {
                              addRemoveGroupPermissions({
                                group_name,
                                data: [
                                  {
                                    permission_name: permissionName,
                                    is_active,
                                  },
                                ],
                              })
                                .unwrap()
                                .then(() => {
                                  toast(
                                    `Group permission updated: ${group_name}'s ${permissionName} is now ${is_active}`,
                                    {
                                      type: "success",
                                    }
                                  );
                                })
                                .catch((err) => {
                                  toast(`An error occured: ${err?.message}`, {
                                    type: "error",
                                  });
                                });
                              formikProps.setFieldValue(
                                `userPermissionWithGroups.${group_name}.${permissionName}`,
                                is_active,
                                true
                              );
                            }}
                          />
                        )
                      )}
                  </Grid>
                </Grid>
              </CustomTabPanel>
              <CustomTabPanel value={value} index={1}>
                <Grid item xs={12}>
                  <Grid container spacing={2}>
                    <MenuTreeGraph />
                  </Grid>
                </Grid>
              </CustomTabPanel>
              <CustomTabPanel value={value} index={2}>
                <Grid item xs={12}>
                  <Grid container spacing={2}>
                    <AccessTreeGraph />
                  </Grid>
                </Grid>
              </CustomTabPanel>

              <Modal
                message={<AddPermission handleClose={handleClose} />}
                sx={modalSx}
                Header={<Typography variant="h4">Add Permission</Typography>}
                width={isMobile ? "90%" : "25%"}
                height="auto"
                modalContentSx={{ height: "auto" }}
                modalOverlaySx={modalOverlaySx}
                open={addModal}
                onClose={handleClose}
                closeButton
              />
            </Form>
          );
        }}
      </Formik>
    </Layout>
  );
};
export default ManageUserGroups;

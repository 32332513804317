import { Divider, Stack, Typography } from "@mui/material";

import CurrencyExchangeIcon from "@mui/icons-material/CurrencyExchange";

import LoadingButton from "@mui/lab/LoadingButton";
import {
  useAddSofrRateMutation,
  useGetSofrRatesQuery,
} from "api/api-operations";
import { useGetFxRatesQuery } from "api/api-vendorsv2";
import SOFR from "assets/navigationIcons/sofr.svg?react";
import MainCard from "components/Common/Cards/MainCard";
import SkeletonLoad from "components/Common/SkeletonLoad";
import useGetCMASofrRates from "hooks/useGetCMASofrRates";
import moment from "moment";
import { PERMISSIONS } from "permissions";
import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { useAppSelector } from "redux/hooks";
import DateRange from "./DateRange";
import FxRates from "./FxRates";
import SOFRTable from "./SOFRTable";

const HealthCheck: React.FC = () => {
  const USER_PERMISSIONS = useAppSelector(
    (state) => state.appState.user_permission
  );
  const IS_DASHBOARD_ADMIN = USER_PERMISSIONS?.includes(
    PERMISSIONS.dashboard_admin
  );
  const IS_MONDAY = moment().isoWeekday() === 1;

  const { data: fxRates, refetch } = useGetFxRatesQuery({
    from: moment()
      .subtract(IS_MONDAY ? 3 : 2, "days")
      .startOf("day")
      .format("YYYY-MM-DD"),
    to: moment().format("YYYY-MM-DD"),
  });
  const [startDate, setStartDate] = useState<Date | undefined>(
    moment(fxRates?.[0]?.date).subtract(2, "days").startOf("day").toDate()
  );
  const [endDate, setEndDate] = useState<Date | undefined>(
    moment(fxRates?.[0]?.date).toDate()
  );
  const [rateToUpdate, setRateToUpdate] = useState<{
    date: string;
    rate: number;
  }>();
  const maxDate = moment(fxRates?.[0]?.date).toDate();
  const SOFR_DATE_LIMIT = moment()
    .subtract(IS_MONDAY ? 3 : 1, "days")
    .format("YYYY-MM-DD");

  const {
    data: sofrRates,
    isLoading,
    refetch: refetchSofr,
  } = useGetSofrRatesQuery({
    from: moment()
      .subtract(IS_MONDAY ? 6 : 9, "days")
      .startOf("day")
      .format("YYYY-MM-DD"),
    to: SOFR_DATE_LIMIT,
  });
  const IS_SOFR_UPDATED = moment(sofrRates?.[0]?.date).isSame(
    SOFR_DATE_LIMIT,
    "day"
  );
  const [updateSofr, { isLoading: isLoadingAddSofr }] =
    useAddSofrRateMutation();
  const { data: cmaSOFR, isFetching, fetchSofrRates } = useGetCMASofrRates();

  useEffect(() => {
    const updateSofrRate = async () => {
      if (!IS_SOFR_UPDATED && cmaSOFR?.length === 0 && !isFetching) {
        const res = await fetchSofrRates();
        const sofrRate = (res ?? []).find(
          (sofr) => moment(sofr?.date).format("YYYY-MM-DD") === SOFR_DATE_LIMIT
        );
        if (sofrRate) {
          const rate = parseFloat(
            sofrRate?.rates.sofrRatesFixing.find((rate) => rate.term === "3M")
              ?.price ?? "0.00"
          );
          setRateToUpdate({
            date: moment(sofrRate.date).format("YYYY-MM-DD"),
            rate,
          });
        }
      }
    };

    updateSofrRate();
  }, [IS_SOFR_UPDATED, cmaSOFR, isFetching, fetchSofrRates]);

  return (
    <Stack spacing={2}>
      <MainCard
        title={
          <Stack spacing={1}>
            <Stack spacing={2} direction="row" alignItems="center">
              <SOFR />
              <Typography variant="h3">SOFR Rates</Typography>
            </Stack>
          </Stack>
        }
        headerSx={{
          px: 2,
          py: 1,
        }}
        headerConfig={{
          syncAction: refetchSofr,
        }}
        content
        contentSX={{
          px: 1,
          py: 1,
        }}
      >
        {isLoading && <SkeletonLoad bars={10} />}
        {!isLoading && sofrRates && (
          <Stack spacing={2}>
            {!IS_SOFR_UPDATED && IS_DASHBOARD_ADMIN && (
              <Stack
                direction="row"
                spacing={1}
                alignItems="center"
                justifyContent="center"
              >
                <Typography color="error">
                  {rateToUpdate
                    ? "Rate available for "
                    : "SOFR rates unavailable for "}
                  {moment(SOFR_DATE_LIMIT).format("Do MMM 'YY")}
                </Typography>
                <LoadingButton
                  variant="contained"
                  loading={isFetching || isLoadingAddSofr}
                  onClick={() => {
                    if (rateToUpdate)
                      updateSofr({
                        date: rateToUpdate?.date,
                        rate: rateToUpdate?.rate ?? 0,
                      })
                        .unwrap()
                        .then(() => {
                          toast.success("SOFR rates updated successfully");
                        })
                        .catch((e) =>
                          toast.error(
                            `Failed to save SOFR rates: ${e?.message}`
                          )
                        );
                  }}
                >
                  Update
                </LoadingButton>
              </Stack>
            )}
            <SOFRTable data={sofrRates} />
          </Stack>
        )}
      </MainCard>
      <MainCard
        title={
          <Stack spacing={1}>
            <Stack spacing={2} direction="row" alignItems="center">
              <CurrencyExchangeIcon />
              <Typography variant="h3">Fx Rates</Typography>
            </Stack>
            <Divider />
          </Stack>
        }
        subheader={
          <DateRange
            from={startDate}
            to={endDate}
            setStartDate={setStartDate}
            setEndDate={setEndDate}
            maxDate={maxDate}
          />
        }
        headerConfig={{
          syncAction: refetch,
        }}
        headerSx={{
          px: 2,
          py: 1,
        }}
        content
        contentSX={{
          px: 1,
          py: 1,
        }}
      >
        <FxRates from={startDate} to={endDate} />
      </MainCard>
    </Stack>
  );
};

export default HealthCheck;

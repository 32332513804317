import * as Sentry from "@sentry/react";
//eslint-disable-next-line import/no-unresolved
import CobrowseIO from "cobrowse-sdk-js";
import {
  ACCOUNTS,
  ADD_COMPANY,
  ADD_CREDIT_LIMITS,
  ADD_PARTNERS,
  BALANCES_LINK,
  CAMPAIGN_TESTING,
  COMPANIES,
  CREDIT_LIMITS_LINK,
  DASHBOARD,
  EMAILS_LINK,
  FUNDER_LINK,
  HEALTH_CHECK_LINK,
  INCOMLEND_INTERNAL_LINK,
  IT_LINK,
  LEADS,
  LEADS_TABLE,
  LEGAL_LINK,
  MAPS,
  ORG_CHART_LINK,
  REGISTER_FOR_FINANCING,
  RISK_LINK,
  SOFR_LINK,
  SUPPORT,
  SUPPORT_EMAIL,
  TAX_INVOICES_LINK,
  TERMSHEETS_LINK,
  TR_LINK,
  USERS_LINK,
} from "codes";
import "core-js/stable";
import {
  VITE_BUYER_PAGE,
  VITE_EXPERIMENTAL_EWALLET,
  VITE_EXPERIMENTAL_GUIDES,
  VITE_EXPERIMENTAL_RISK,
  VITE_INVOICES_PAGE,
} from "experimental_features";
import history from "helpers/history";
import Auth from "layoutRoutes/LayoutAuth";

import Countries from "pages/countries";
import Funder from "pages/invoice-statuses";
import "react-app-polyfill/ie9";
import "react-app-polyfill/stable";
//eslint-disable-next-line import/no-unresolved
import "vite/modulepreload-polyfill";

import BankAccountProfile from "pages/company/banks/profile";
import CLDetail from "pages/credit-limits/cl-detail";

import AdminAddedSignupComponent from "pages/signup/welcome";
import MFA from "pages/thank-you";
import PartnerProfile from "pages/trading-relationships/partner";
import Users from "pages/users";
import UserProfile from "pages/users/profile";
import React, { lazy, Suspense } from "react";

import RegisterCompany from "components/Company/Registration/RegisterCompany";
import KnowledgeCentre from "components/KnowledgeCentre";
import PresalesLR from "layoutRoutes/LayoutPresales";
import RegisterLR from "layoutRoutes/LayoutRegister";
import "normalize.css";
import CreditLimitPage from "pages/company/add/credit-limit";
import AddPartnerPage from "pages/company/add/partner";
import "react-perfect-scrollbar/dist/css/styles.css";

import type {} from "@mui/material/themeCssVarsAugmentation";
import Loadable from "components/Common/extended/Loadable";
import IncomlendInternalLR from "layoutRoutes/LayoutIncomlendInternal";
import BalancesPage from "pages/balances";
import CargoTracking from "pages/cargo-tracking";
import SelectProfile from "pages/company/add/register";
import ViewEditEligibility from "pages/countries/eligibility";
import CreditLimits from "pages/credit-limits";
import OnboardingsPage from "pages/credit-limits/onboardings";
import StatusesPage from "pages/credit-limits/statuses";
import GuidePage from "pages/guides";
import InvoiceStatusPage from "pages/invoice-statuses/invoice-status";
import ITUserManagement from "pages/it/users";
import ManageUserAccount from "pages/it/users/manage";
import PlatformUsage from "pages/platform-usage";
import AccountsListingPage from "pages/select-account";

import ErrorPage from "components/Common/ErrorPage";
import Loader from "components/Common/Loader";
import Accounts from "pages/accounts";
import Account from "pages/accounts/account";
import CreditLimitOpportunities from "pages/credit-limits-opportunities";
import DashboardInternal from "pages/dashboard-internal";
import Emails from "pages/emails";
import ExperimentalFeatures from "pages/it/experimental-features";
import ManageUserGroups from "pages/it/groups";
import ScriptsPage from "pages/it/scripts";
import Leads from "pages/leads";

import HealthCheck from "components/HealthCheck";
import { clearStorage } from "helpers/clearStorage";
import LeadAccount from "pages/leads/lead";
import Maps from "pages/maps";
import Map from "pages/maps/map";
import OrgChartPage from "pages/org-chart";
import Settings from "pages/settings";
import SOFRPage from "pages/sofr-rates";
import SupportPage from "pages/support";
import IssuePage from "pages/support/issue";
import TaxInvoicesPage from "pages/tax-invoices";
import TaxInvoice from "pages/tax-invoices/invoice";
import Termsheets from "pages/termsheets";
import TermSheetForm from "pages/termsheets/termsheet";
import TestCampaign from "pages/test-campaign";
import Totp from "pages/twofa";
import ReactDOM from "react-dom/client";
import { HelmetProvider } from "react-helmet-async";
import { Provider } from "react-redux";
import {
  createRoutesFromChildren,
  unstable_HistoryRouter as HistoryRouter,
  matchRoutes,
  Route,
  Routes,
  useLocation,
  useNavigationType,
} from "react-router-dom";
import "react-toastify/dist/ReactToastify.css";
import { PersistGate } from "redux-persist/integration/react";
import { persistor, store } from "redux/store";
import Root from "root";
import packageInfo from "../package.json";
import Incomlend from "./incomlend";
import * as serviceWorkerRegistration from "./serviceWorkerRegistration";

const { version } = packageInfo;

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
const SentryRoutes = Sentry.withSentryReactRouterV6Routing(Routes);

Sentry.init({
  dsn: import.meta.env.VITE_SENTRY_DSN,
  environment: import.meta.env.VITE_ENV,
  tracesSampleRate: import.meta.env.VITE_ENV === "main" ? 1.0 : 0.25,
  tracePropagationTargets: ["localhost", /^https:\/\/incomlend\.com\/api/],
  enabled: import.meta.env.VITE_ENV === "main",
  release: version,
  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 1.0,
  integrations: [
    Sentry.reactRouterV6BrowserTracingIntegration({
      useEffect: React.useEffect,
      useLocation,
      useNavigationType,
      createRoutesFromChildren,
      matchRoutes,
    }),
    Sentry.replayIntegration(),
  ],
});

const { push, back } = history;

const Dashboard = Loadable(lazy(() => import("pages/dashboard")));
const Companies = Loadable(lazy(() => import("pages/company")));

const SignupComponent = Loadable(lazy(() => import("pages/signup")));
const TradingRelationShips = Loadable(
  lazy(() => import("pages/trading-relationships"))
);

const TermsOfUse = Loadable(lazy(() => import("pages/terms-of-use")));
const Privacy = Loadable(lazy(() => import("pages/privacy-policy")));
const Login = Loadable(lazy(() => import("pages/login")));
const CLRequest = Loadable(lazy(() => import("pages/cl-requests")));
const Buyer = Loadable(lazy(() => import("pages/buyer")));
const Invoices = Loadable(lazy(() => import("pages/invoices")));
const Invoice = Loadable(lazy(() => import("pages/invoices/invoice")));
const Ewallet = Loadable(lazy(() => import("pages/e-wallet")));
const Transaction = Loadable(lazy(() => import("pages/transaction")));
const LeadsTable = Loadable(lazy(() => import("pages/leads-table")));

const Allocation = Loadable(
  lazy(() => import("pages/cl-requests/allocations"))
);
const Submission = Loadable(lazy(() => import("pages/submission")));

if ("BroadcastChannel" in window) {
  const updateChannel = new BroadcastChannel("sw-update-channel");
  updateChannel.onmessage = (event) => {
    if (event.data && event.data.type === "NEW_VERSION") {
      window.location.reload(); // Force reload on new version notification
      clearStorage(store.dispatch);
    }
  };
}

root.render(
  // <CacheBuster isEnabled currentVersion={version} metaFileDirectory={"."}>
  <HelmetProvider>
    <React.StrictMode>
      <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
          {/* @ts-expect-error */}
          <HistoryRouter history={history}>
            <SentryRoutes>
              <Route element={<Root />}>
                <Route element={<Auth />}>
                  <Route path="/login" element={<Login />} />
                  <Route path="/thank-you" element={<MFA />} />
                  <Route path="/confirm-otp" element={<Totp />} />
                  <Route path="/signup" element={<SignupComponent />} />
                  <Route
                    path="/welcome"
                    element={<AdminAddedSignupComponent />}
                  />
                  <Route path="/terms-of-use" element={<TermsOfUse />} />
                  <Route path="/privacy-policy" element={<Privacy />} />
                  {/* App-Incomlend */}
                  <Route
                    path="/dashboard/terms-of-use"
                    element={<TermsOfUse />}
                  />
                  <Route
                    path="/dashboard/privacy-policy"
                    element={<Privacy />}
                  />
                  {/* GTC links*/}
                  {/* <Route
                      path="/bank-fees-details"
                      element={<BankFeesDetails />}
                    />
                    <Route
                      path="/fees-and-charges"
                      element={<FeesAndCharges />}
                    />
                    <Route path="/gtc-suppliers" element={<GTCSuppliers />} />
                    <Route path="/gtc-buyers" element={<GTCBuyers />} />
                    <Route path="/gtc-funders" element={<GTCFunders />} />
                    <Route
                      path="/how-the-reservation-process-works"
                      element={<ReservationProcess />}
                    />
                    <Route
                      path="/incomlend-bank-account-details-amicorp"
                      element={<></>}
                    />
                    <Route
                      path="/incomlend-bank-account-details-buyers-trust"
                      element={<></>}
                    />
                    <Route
                      path="/incomlend-bank-account-details-buyers"
                      element={<></>}
                    />
                    <Route path="/privacy-policy-gtc" element={<></>} />
                    <Route path="/terms-and-conditions-gtc" element={<></>} /> */}
                  {/* end GTC*/}
                </Route>

                <Route element={<Incomlend />}>
                  <Route element={<RegisterLR />}>
                    <Route path={ADD_COMPANY} element={<RegisterCompany />} />
                  </Route>
                  <Route element={<PresalesLR />}>
                    <Route path="/" element={<Dashboard />} />
                    <Route
                      path={REGISTER_FOR_FINANCING}
                      element={<SelectProfile />}
                    />
                    {/* //Request from OPS on Feb 2024 to remove doc upload on presales */}
                    {/* <Route
                        path={UPLOAD_DOCUMENTS}
                        element={<UploadDocumentsPage />}
                      /> */}
                    <Route path={ADD_PARTNERS} element={<AddPartnerPage />} />
                    <Route
                      path={ADD_CREDIT_LIMITS}
                      element={<CreditLimitPage />}
                    />
                  </Route>
                  <Route
                    path="/knowledge-centre"
                    element={<KnowledgeCentre />}
                  />
                  <Route path="/accounts" element={<AccountsListingPage />} />

                  <Route path="/cargo-tracking" element={<CargoTracking />} />

                  <Route path={DASHBOARD} element={<Dashboard />} />
                  <Route path={COMPANIES} element={<Companies />} />
                  <Route path={TR_LINK} element={<TradingRelationShips />} />
                  <Route
                    path={`${TR_LINK}/profile/:id`}
                    element={<PartnerProfile />}
                  />
                  <Route path="/settings" element={<Settings />} />
                  <Route path="/submission" element={<Submission />} />
                  <Route
                    path={INCOMLEND_INTERNAL_LINK}
                    element={<IncomlendInternalLR />}
                  >
                    <Route
                      path={`${INCOMLEND_INTERNAL_LINK}`}
                      element={<DashboardInternal />}
                    />
                    <Route
                      path={`${INCOMLEND_INTERNAL_LINK}/platform-usage`}
                      element={<PlatformUsage />}
                    />
                    <Route
                      path={`${INCOMLEND_INTERNAL_LINK}/credit-limits-opportunities`}
                      element={<CreditLimitOpportunities />}
                    />
                    <Route
                      path={`${INCOMLEND_INTERNAL_LINK}${EMAILS_LINK}`}
                      element={<Emails />}
                    />
                    {VITE_EXPERIMENTAL_GUIDES && (
                      <Route
                        path={`${INCOMLEND_INTERNAL_LINK}/guides`}
                        element={<GuidePage />}
                      />
                    )}

                    <Route
                      path={`${INCOMLEND_INTERNAL_LINK}${ORG_CHART_LINK}`}
                      element={<OrgChartPage />}
                    />
                    <Route
                      path={`${INCOMLEND_INTERNAL_LINK}${SUPPORT}`}
                      element={<SupportPage />}
                    />
                    <Route
                      path={`${INCOMLEND_INTERNAL_LINK}${SUPPORT}/:id`}
                      element={<IssuePage />}
                    />
                    <Route
                      path={`${INCOMLEND_INTERNAL_LINK}${MAPS}`}
                      element={<Maps />}
                    />
                    <Route
                      path={`${INCOMLEND_INTERNAL_LINK}${MAPS}/:id`}
                      element={<Map />}
                    />
                    {VITE_EXPERIMENTAL_RISK && (
                      <>
                        <Route
                          path={`${INCOMLEND_INTERNAL_LINK}${RISK_LINK}`}
                          element={<CLRequest />}
                        />
                        <Route
                          path={`${INCOMLEND_INTERNAL_LINK}${RISK_LINK}/:id/allocations`}
                          element={<Allocation />}
                        />
                      </>
                    )}

                    <Route
                      path={`${INCOMLEND_INTERNAL_LINK}${FUNDER_LINK}`}
                      element={<Funder />}
                    />
                    <Route
                      path={`${INCOMLEND_INTERNAL_LINK}${FUNDER_LINK}/:id`}
                      element={<InvoiceStatusPage />}
                    />
                    <Route
                      path={`${INCOMLEND_INTERNAL_LINK}${LEGAL_LINK}`}
                      element={<Countries />}
                    />
                    <Route
                      path={`${INCOMLEND_INTERNAL_LINK}${LEGAL_LINK}/:iso3/view-eligibility`}
                      element={<ViewEditEligibility />}
                    />

                    <Route
                      path={`${INCOMLEND_INTERNAL_LINK}${CREDIT_LIMITS_LINK}`}
                      element={<CreditLimits />}
                    />

                    <Route
                      path={`${INCOMLEND_INTERNAL_LINK}${CREDIT_LIMITS_LINK}/:id`}
                      element={<CLDetail />}
                    />
                    <Route
                      path={`${INCOMLEND_INTERNAL_LINK}/onboardings`}
                      element={<OnboardingsPage />}
                    />
                    <Route
                      path={`${INCOMLEND_INTERNAL_LINK}${ACCOUNTS}`}
                      element={<Accounts />}
                    />
                    <Route
                      path={`${INCOMLEND_INTERNAL_LINK}${ACCOUNTS}/:id`}
                      element={<Account />}
                    />
                    <Route
                      path={`${INCOMLEND_INTERNAL_LINK}${LEADS}`}
                      element={<Leads />}
                    />
                    <Route
                      path={`${INCOMLEND_INTERNAL_LINK}${LEADS}/:id`}
                      element={<LeadAccount />}
                    />
                    <Route
                      path={`${INCOMLEND_INTERNAL_LINK}${CAMPAIGN_TESTING}`}
                      element={<TestCampaign />}
                    />

                    <Route
                      path={`${INCOMLEND_INTERNAL_LINK}/onboarding-statuses`}
                      element={<StatusesPage />}
                    />

                    <Route
                      path={`${INCOMLEND_INTERNAL_LINK}${LEADS_TABLE}`}
                      element={<LeadsTable />}
                    />

                    <Route
                      path={`${INCOMLEND_INTERNAL_LINK}${IT_LINK}/user-management`}
                      element={<ITUserManagement />}
                    />
                    <Route
                      path={`${INCOMLEND_INTERNAL_LINK}${IT_LINK}/user-management/:id/manage`}
                      element={<ManageUserAccount />}
                    />
                    <Route
                      path={`${INCOMLEND_INTERNAL_LINK}${IT_LINK}/groups`}
                      element={<ManageUserGroups />}
                    />
                    <Route
                      path={`${INCOMLEND_INTERNAL_LINK}${IT_LINK}/experimental-features`}
                      element={<ExperimentalFeatures />}
                    />
                    <Route
                      path={`${INCOMLEND_INTERNAL_LINK}${IT_LINK}/scripts`}
                      element={<ScriptsPage />}
                    />

                    <Route
                      path={`${INCOMLEND_INTERNAL_LINK}/onboarding-statuses`}
                      element={<StatusesPage />}
                    />
                    <Route
                      path={`${INCOMLEND_INTERNAL_LINK}${BALANCES_LINK}`}
                      element={<BalancesPage />}
                    />
                    <Route
                      path={`${INCOMLEND_INTERNAL_LINK}${SOFR_LINK}`}
                      element={<SOFRPage />}
                    />
                    <Route
                      path={`${INCOMLEND_INTERNAL_LINK}${HEALTH_CHECK_LINK}`}
                      element={<HealthCheck />}
                    />
                    <Route
                      path={`${INCOMLEND_INTERNAL_LINK}${TAX_INVOICES_LINK}`}
                      element={<TaxInvoicesPage />}
                    />
                    <Route
                      path={`${INCOMLEND_INTERNAL_LINK}${TAX_INVOICES_LINK}/:id`}
                      element={<TaxInvoice />}
                    />

                    <Route
                      path={`${INCOMLEND_INTERNAL_LINK}${TERMSHEETS_LINK}`}
                      element={<Termsheets />}
                    />
                    <Route
                      path={`${INCOMLEND_INTERNAL_LINK}${TERMSHEETS_LINK}/:id`}
                      element={<TermSheetForm />}
                    />
                  </Route>
                  {VITE_BUYER_PAGE && (
                    <Route path="/buyer/:id" element={<Buyer />} />
                  )}
                  {VITE_INVOICES_PAGE && (
                    <>
                      <Route path="/invoices" element={<Invoices />} />
                      <Route path="/invoice/:id" element={<Invoice />} />
                    </>
                  )}
                  {VITE_EXPERIMENTAL_EWALLET && (
                    <>
                      <Route path="/ewallet" element={<Ewallet />} />
                      <Route
                        path="/transaction/:id"
                        element={<Transaction />}
                      />
                    </>
                  )}
                  <Route element={<Users />} path={USERS_LINK} />
                  <Route element={<UserProfile />} path={`${USERS_LINK}/:id`} />
                  <Route
                    element={<BankAccountProfile />}
                    path="/banks/:bankaccount"
                  />
                </Route>

                <Route
                  path="*"
                  element={
                    <Suspense fallback={<Loader open />}>
                      <ErrorPage
                        goBack={back}
                        push={push}
                        address={SUPPORT_EMAIL}
                      />
                    </Suspense>
                  }
                />
              </Route>
            </SentryRoutes>
          </HistoryRouter>
        </PersistGate>
      </Provider>
    </React.StrictMode>
  </HelmetProvider>
  //  </CacheBuster>
);

"serviceWorker" in navigator && serviceWorkerRegistration.register();

CobrowseIO.license = import.meta.env.VITE_COBROWSE_LICENSE;
CobrowseIO.start();

import SyncIcon from "@mui/icons-material/Sync"; // Import sync icon
import {
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Divider,
  IconButton,
  type SxProps,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { forwardRef } from "react";

// ==============================|| CUSTOM MAIN CARD ||============================== //

interface MainCardProps {
  border?: boolean;
  boxShadow?: boolean;
  children?: React.ReactNode;
  content?: boolean;
  contentClass?: string;
  contentSX?: SxProps;
  darkTitle?: boolean;
  secondary?: React.ReactNode;
  shadow?: string;
  sx?: SxProps;
  title?: React.ReactNode;
  headerSx?: SxProps;
  footerSx?: SxProps;
  subheader?: React.ReactNode;
  footer?: React.ReactNode;
  headerConfig?: {
    syncAction?: () => void;
  };
}

const MainCard = forwardRef<HTMLDivElement, MainCardProps>(
  (
    {
      border = true,
      boxShadow,
      children,
      content = true,
      contentClass = "",
      contentSX = {},
      darkTitle,
      secondary,
      shadow,
      sx = {},
      title,
      subheader,
      footer,
      headerSx = { "& .MuiCardHeader-action": { mr: 0 } },
      footerSx,
      headerConfig,
      ...others
    },
    ref
  ) => {
    const theme = useTheme();

    return (
      <Card
        ref={ref}
        {...others}
        sx={{
          border: border ? "1px solid" : "none",
          borderColor: theme.palette.primary.light,
          ":hover": {
            boxShadow: boxShadow
              ? shadow || "0 2px 14px 0 rgb(32 40 45 / 8%)"
              : "inherit",
          },
          ...sx,
        }}
      >
        {/* card header and action */}
        {title && (
          <CardHeader
            sx={headerSx}
            title={title}
            action={
              <>
                {secondary}
                {headerConfig?.syncAction && (
                  <IconButton onClick={headerConfig.syncAction}>
                    <SyncIcon />
                  </IconButton>
                )}
              </>
            }
            subheader={subheader}
          />
        )}

        {/* content & header divider */}
        {title && <Divider />}

        {/* card content */}
        {content && (
          <CardContent sx={contentSX} className={contentClass}>
            {children}
          </CardContent>
        )}
        {!content && children}

        {/* footer & header divider */}
        {footer && <Divider />}

        {/* card footer */}
        {footer && <CardActions sx={footerSx}>{footer}</CardActions>}
      </Card>
    );
  }
);

export default MainCard;

import { Info } from "@mui/icons-material";
import {
  Autocomplete,
  AutocompleteChangeDetails,
  AutocompleteChangeReason,
  InputLabel,
  AutocompleteProps as MuiAutocompleteProps,
  Stack,
  TextField,
  Tooltip,
  TooltipProps,
  Typography,
} from "@mui/material";
import match from "autosuggest-highlight/match";
import parse from "autosuggest-highlight/parse";
import { useField } from "formik";
import { uniqueObject } from "helpers/unique";

interface AutoCompleteProps<T>
  extends Omit<
    MuiAutocompleteProps<T, boolean, boolean, boolean>,
    "renderInput" | "options" | "key"
  > {
  name: string;
  data: T[];
  labelKey: string | T;
  id?: string;
  disabled?: boolean;
  multiple?: boolean;
  placeholder?: string;
  value?: any;
  handleSave?: (value: T[], reason?: AutocompleteChangeReason) => void;
  limitTags?: number;
  required?: boolean;
  helpText?: TooltipProps["title"];
  label?: string;
}

const AutoComplete = <T extends Record<string, any> | string>({
  id,
  data,
  labelKey,
  disabled = false,
  name,
  multiple = false,
  placeholder,
  value,
  handleSave,
  limitTags,
  required,
  helpText,
  label,
  ...rest
}: AutoCompleteProps<T>) => {
  const [field, meta, helpers] = useField(name);

  const computedValue = value || field.value;

  const Label = ({ label }: { label: string }) => (
    <Stack
      alignItems="center"
      justifyContent="start"
      spacing={0.5}
      direction="row"
      mb={0.5}
      height="2.3em"
      width="100%"
    >
      <Typography fontWeight="bold">{label && `${label}`}</Typography>
      {required && <Typography color="error">*</Typography>}
      {Boolean(helpText) && (
        <Tooltip title={helpText} enterTouchDelay={0} leaveDelay={200}>
          <Info color="primary" sx={{ marginTop: "6px" }} />
        </Tooltip>
      )}
      <InputLabel />
    </Stack>
  );

  return (
    <Stack alignItems="start" justifyContent="start">
      {label && <Label label={label} />}
      <Autocomplete
        id={id}
        multiple={multiple}
        options={data ? uniqueObject(data, labelKey as string) : []}
        getOptionLabel={(option) => {
          if (option)
            if (typeof option === "string") return option;
            else if (typeof option === "object")
              return (option as Record<string, any>)?.[labelKey as string];
          return "";
        }}
        getOptionDisabled={(option) => (option as Record<string, any>).disabled}
        value={computedValue || (multiple ? [] : placeholder)}
        onChange={
          rest.onChange
            ? rest.onChange
            : (
                event: any,
                value: T | NonNullable<string | T> | (string | T)[] | null,
                reason: AutocompleteChangeReason,
                details?: AutocompleteChangeDetails<T> | undefined
              ) => {
                handleSave && value && handleSave(value as T[], reason);
                helpers.setValue(value);
              }
        }
        sx={{
          fontSize: "1em",
          ".MuiAutocomplete-inputRoot": {
            height: multiple ? "3.7em" : "2.3em",
          },
          ".MuiAutocomplete-tag, input": {
            transform: multiple ? "translateY(-11px)" : "translateY(11px)",
          },
          width: "100%",
        }}
        renderInput={(params) => (
          <TextField {...params} variant="outlined" placeholder={placeholder} />
        )}
        renderOption={(props, option, { inputValue }) => {
          const name = (option as Record<string, any>)?.[labelKey as string];
          if (name) {
            const matches = match(name, inputValue);
            const parts = parse(name, matches);
            return (
              <li {...props} key={props?.key}>
                <div>
                  {parts.map((part) => (
                    <span
                      key={part.text}
                      style={{
                        fontWeight: part.highlight ? 700 : 400,
                      }}
                    >
                      {part.text}
                    </span>
                  ))}
                </div>
              </li>
            );
          }
          return <li {...props}>Not defined</li>;
        }}
        disabled={disabled}
        limitTags={limitTags}
        {...rest}
      />
    </Stack>
  );
};
export default AutoComplete;

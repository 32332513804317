import { Grid, Typography } from "@mui/material";
import { useGetBuyerCreditLimitQuery } from "api/api-accounts";
import { BUYER } from "codes";
import NoData from "components/Common/NoData";
import SkeletonLoad from "components/Common/SkeletonLoad";
import { currencyFormatter } from "helpers/currencyFormatter";
import type { Account } from "types";

const AvailableCreditLimit = ({ buyer }: { buyer: Account }) => {
  const { profile } = buyer;
  const {
    data: availableCreditLimit,
    isFetching: isFetchingCreditLimit,
    status,
    refetch: refetchCL,
  } = useGetBuyerCreditLimitQuery(buyer?.id ?? "", {
    skip: profile !== BUYER.toLowerCase(),
  });

  const SUCCESFUL_CL_CALL =
    !isFetchingCreditLimit && availableCreditLimit && status !== "rejected";
  const NO_CL = !isFetchingCreditLimit && status === "rejected";

  return (
    <>
      {isFetchingCreditLimit && <SkeletonLoad bars={10} />}
      {NO_CL && (
        <Grid item xs={12}>
          <NoData text="No Credit Limit Available" />
        </Grid>
      )}
      {SUCCESFUL_CL_CALL && (
        <Grid item xs={12}>
          <Typography
            sx={{
              fontWeight: "bold",
              padding: 1,
              borderRadius: 1,
              fontSize: "1.5em",
            }}
          >
            Available Credit Limit:{" "}
            {availableCreditLimit && availableCreditLimit.currency}{" "}
            {availableCreditLimit &&
              currencyFormatter({
                amount:
                  availableCreditLimit?.approved_amount ||
                  availableCreditLimit?.atradius_amount ||
                  availableCreditLimit?.requested_amount,
              })}
          </Typography>
        </Grid>
      )}
    </>
  );
};

export default AvailableCreditLimit;

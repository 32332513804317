import { Sync } from "@mui/icons-material";
import LoadingButton from "@mui/lab/LoadingButton";
import { Container, Typography } from "@mui/material";
import {
  useGetInvitedContactsQuery,
  useResendInviteMutation,
} from "api/api-it";
import { useGetCurrentUserQuery } from "api/api-users-v2";
import { DEFAULT_ROWS } from "codes";
import DataTablev2 from "components/Common/DataTablev2";
import NoData from "components/Common/NoData";
import SkeletonLoad from "components/Common/SkeletonLoad";
import useWidth from "helpers/useWidth";
import moment from "moment";
import React, { useRef } from "react";
import InviteStatusIndication from "./InviteStatusIndication";

const InvitedUsers: React.FC = () => {
  const { data, isLoading, refetch } = useGetInvitedContactsQuery(null);
  const TO_BE_HIDDEN = ["invite_token", "contact_id"];

  const { data: curr_user, isFetching: isFetchingUsers } =
    useGetCurrentUserQuery();
  const settings = curr_user?.settings ?? {};
  const rowsPerPage = settings?.rowsPerPage ?? DEFAULT_ROWS;
  const ref = useRef<HTMLDivElement>(null);
  const width = ref ? useWidth(ref) : 1000;

  const defaultColumnWidth = width ? (width / 7) * 1.6 : 200;
  const [resendInvite] = useResendInviteMutation();

  return (
    <Container
      ref={ref}
      sx={{
        p: 0,
      }}
      maxWidth="xl"
    >
      {isLoading && <SkeletonLoad bars={10} />}
      {!isLoading && data && data?.length === 0 && (
        <NoData text="No Invited Users" />
      )}
      {!isLoading && data && data?.length > 0 && (
        <DataTablev2
          data={data?.map((item) => ({
            ...item,
            action: {
              contact_id: item.contact_id,
              status: item.status,
              type: item.contact_type,
            },
          }))}
          toBeHidden={TO_BE_HIDDEN}
          defaultColumnWidth={defaultColumnWidth}
          rowsPerPage={rowsPerPage}
          customColumns={{
            contact_name: {
              contact_name: "Contact Name",
              minWidth: 160,
            },
            contact_type: {
              contact_type: "Type",
              minWidth: 80,
              type: "text",
            },
            status: {
              status: "Status",
              minWidth: 80,
              type: "text",
              renderCell: ({ value }: { value: string }) => {
                return (
                  <InviteStatusIndication
                    status={value}
                    sx={{
                      textAlign: "center",
                    }}
                  />
                );
              },
            },
            invited_by: {
              invited_by: "Invited By",
              minWidth: 160,
              type: "text",
            },
            invited_at: {
              invited_at: "Invited At",
              minWidth: 160,
              type: "date",
              renderCell: ({ value }: { value: string }) => {
                return (
                  <Typography
                    sx={{
                      textAlign: "center",
                    }}
                  >
                    {moment(value).format("DD/MM/YYYY HH:mm")}
                  </Typography>
                );
              },
            },
            invite_accepted_at: {
              invite_accepted_at: "Invite Accepted At",
              minWidth: 160,
              type: "date",

              renderCell: ({ value }: { value: string }) => {
                return (
                  <Typography
                    sx={{
                      color: value ? "green" : "grey",
                      textAlign: "center",
                    }}
                  >
                    {value ? moment(value).format("DD/MM/YYYY HH:mm") : "None"}
                  </Typography>
                );
              },
            },
            action: {
              action: "",
              type: "action",
              renderCell: ({
                value,
              }: {
                value: {
                  contact_id: string;
                  status: string;
                  type: "internal" | "external";
                };
              }) => {
                return (
                  <LoadingButton
                    variant="contained"
                    disabled={value?.status === "ACCEPTED"}
                    endIcon={<Sync />}
                    onClick={() =>
                      resendInvite({
                        contact_id: value?.contact_id,
                        type: value?.type,
                      })
                    }
                  >
                    Resend
                  </LoadingButton>
                );
              },
            },
          }}
        />
      )}
    </Container>
  );
};

export default InvitedUsers;

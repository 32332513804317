import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import DeleteIcon from "@mui/icons-material/Delete";
import ErrorIcon from "@mui/icons-material/Error";
import FileUploadIcon from "@mui/icons-material/FileUpload";
import InfoIcon from "@mui/icons-material/Info";
import {
  Badge,
  Box,
  Button,
  CircularProgress,
  Divider,
  FormControl,
  Grid,
  IconButton,
  Link,
  SwipeableDrawer,
  SxProps,
  Tooltip,
  Typography,
  useTheme,
} from "@mui/material";
import Drop from "components/Common/Drop";
import SkeletonLoad from "components/Common/SkeletonLoad";
import { CompanyDoc } from "components/Map/Sections/Compliance/Screening/types";
import { FormikProps } from "formik";
import toCamelCase from "helpers/camelCase";
import useIsMobile from "hooks/useIsMobile";
import moment from "moment";
import { ChangeEvent, Fragment, useState } from "react";
import { useTranslation } from "react-i18next";
import styles from "./multiupload.module.scss";

interface DocumentUploadFieldProps {
  item: {
    id: string;
    name: string;
    label: string;
    value: string;
    template?: string;
    description: string;
    yearly_set_count?: number;
    count_unique_uploaded: number;
  };
  onUpload: (
    data: { file: File; year: number | null },
    label: string,
    document_type: string,
    idx: number
  ) => void;
  formikProps: FormikProps<any>;
  isPresales?: boolean;
  documents?: CompanyDoc[];
  onView: (fileName: string, id: string, idx: number) => void;
  onDelete?: (fileName: string, id: string, idx: number) => void;
  enableCount?: boolean;
  deleteDisabled?: boolean;
  viewDisabled?: boolean;
  uploadDisabled?: boolean;
  disabled?: boolean;
  isLoading?: boolean;
  documentTypeDescription?: string;
  acceptFileTypes?: string;
  isLoadingSubmission?: boolean;
  sx?: SxProps;
}

const DocumentUploadField: React.FC<DocumentUploadFieldProps> = (props) => {
  const {
    item,
    onUpload,
    formikProps,
    isPresales,
    documents,
    onView,
    onDelete,
    enableCount = true,
    deleteDisabled = false,
    viewDisabled = false,
    uploadDisabled = false,
    disabled,
    isLoading,
    documentTypeDescription,
    acceptFileTypes = "application/pdf,image/x-png,image/jpeg,image/jpg",
    isLoadingSubmission,
    sx,
  } = props;
  const { t } = useTranslation();
  const theme = useTheme();
  const [selectedYear, setSelectedYear] = useState<string>();
  const [file, setFile] = useState<File>();

  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const toggleOpen = () => setOpen(!open);
  const handleClose = () => setOpen(false);
  const isMobile = useIsMobile();
  const index = documents?.length ?? 0;
  if (!item?.yearly_set_count)
    item.yearly_set_count = item.count_unique_uploaded;
  const hasYearTagging = Boolean(item && item?.yearly_set_count > 1);
  const allDocumentsUploaded =
    item.count_unique_uploaded >= item.yearly_set_count &&
    Boolean(item.count_unique_uploaded);

  const handleUpload = (e: ChangeEvent<HTMLInputElement>) => {
    if (e?.target?.files?.length) {
      const value = e?.target?.files[0];
      setFile(value);
      if (hasYearTagging) {
        selectedYear &&
          onUpload(
            { file: value, year: Number(selectedYear) },
            toCamelCase(item.label),
            item.id,
            index
          );
      } else {
        onUpload(
          { file: value, year: null },
          toCamelCase(item.label),
          item.id,
          index
        );
        formikProps.submitForm();
      }
      formikProps.setFieldValue(
        `${toCamelCase(item.label)}-${index}`,
        value,
        true
      );
    }
    e.target.value = "";
  };

  const isError =
    !Boolean(formikProps.values[`${toCamelCase(item.label)}-0`]) &&
    Boolean(formikProps.touched[`${toCamelCase(item.label)}-0`]);
  const isDefault = !Boolean(
    formikProps.touched[`${toCamelCase(item.label)}-0`]
  );
  const border: Record<string, string> = {
    error: `1px solid ${theme.palette.error.main}`,
    success: `1px solid ${theme.palette.success.main}`,
    default: `1px solid ${theme.palette.secondary.main}`,
  };

  const generateLastThreeYears = () => {
    const currentYear = new Date().getFullYear();
    return [currentYear, currentYear - 1, currentYear - 2];
  };

  const menuSx = {
    textAlign: "start",
    ".MuiButtonBase-root": {
      justifyContent: "start",
      ":hover": {
        backgroundColor: theme.palette.primary.main,
        color: "#fff !important",
      },
    },
    ".Mui-selected": {
      ":hover": {
        backgroundColor: `${theme.palette.primary.main} !important`,
        color: "#fff !important",
      },
    },
  };
  const nestedMenuSx = {
    ".MuiButtonBase-root": {
      ":hover": {
        backgroundColor: theme.palette.primary.main,
        color: "#fff !important",
      },
    },
    ".Mui-selected": {
      ":hover": {
        backgroundColor: theme.palette.primary.main,
        color: "#fff !important",
      },
    },
  };

  return (
    <FormControl error={isError} data-testid={`${item.label}-field`} fullWidth>
      {enableCount && (
        <Badge
          anchorOrigin={{
            vertical: "top",
            horizontal: "left",
          }}
          sx={{ ".MuiBadge-badge": { width: "8ch", left: "3ch" } }}
          badgeContent={
            <Typography
              sx={{
                bgcolor:
                  item.count_unique_uploaded >= item.yearly_set_count
                    ? "green"
                    : theme.palette.error.main,
                color: "white !important",
                padding: "0 0.9ch",
                borderRadius: "8px",
                fontSize: "1em",
              }}
            >{`${item.count_unique_uploaded} of ${item.yearly_set_count}`}</Typography>
          }
        />
      )}
      <Grid
        container
        sx={{
          border: border[isError ? "error" : isDefault ? "default" : "success"],
          borderRadius: "10px",
          minHeight: "16vh",
          bgcolor: disabled ? "#ced4da" : theme.palette.background.paper,
          ...sx,
        }}
        justifyContent="center"
        spacing={0.5}
      >
        {isLoading && <SkeletonLoad bars={2} />}
        {!isLoading && (
          <Fragment>
            <Grid item xs={11} sx={{ mt: 1 }}>
              <Button
                variant="contained"
                className={styles.mobileUpload}
                component="label"
                fullWidth
                data-testid={`${item.label}-upload`}
                disabled={disabled || uploadDisabled}
              >
                <Grid container alignItems="start">
                  <Grid item xs={10}>
                    <Grid
                      container
                      alignItems="center"
                      spacing={1}
                      flexWrap="nowrap"
                    >
                      {!isPresales && (
                        <Grid
                          item
                          className={`${styles.docTitle}`}
                          sx={{ mt: 1 }}
                        >
                          {allDocumentsUploaded ? (
                            <CheckCircleIcon
                              color={disabled ? "disabled" : "success"}
                            />
                          ) : (
                            <ErrorIcon
                              color={disabled ? "disabled" : "error"}
                            />
                          )}
                        </Grid>
                      )}
                      <Grid
                        item
                        className={`${styles.docTitle}`}
                        sx={{ mt: 1 }}
                      >
                        <Typography
                          color={
                            disabled
                              ? theme.palette.secondary.main
                              : theme.palette.primary.main
                          }
                          sx={{
                            textTransform: "initial",
                            fontSize: !isMobile ? "1em" : "0.7em",
                            fontWeight: 600,
                          }}
                        >
                          {item.label}
                        </Typography>
                      </Grid>
                      {!isMobile && (
                        <Grid item xs={1}>
                          <Tooltip
                            title={
                              item.description !== ""
                                ? item.description
                                : item.label
                            }
                            placement="right-end"
                          >
                            <InfoIcon
                              sx={{ mt: 1.5 }}
                              color={disabled ? "disabled" : "primary"}
                            />
                          </Tooltip>
                        </Grid>
                      )}
                    </Grid>
                  </Grid>
                  <Grid
                    item
                    xs={2}
                    sx={{ p: 1, textAlign: "center", cursor: "pointer" }}
                  >
                    {isError || isDefault ? (
                      <FileUploadIcon
                        color={
                          disabled || uploadDisabled ? "disabled" : "primary"
                        }
                      />
                    ) : (
                      <CheckCircleIcon color="success" />
                    )}
                  </Grid>
                  {isLoadingSubmission ? (
                    <Grid item>
                      <CircularProgress size={15} />
                    </Grid>
                  ) : (
                    <Grid item>
                      <input
                        type="file"
                        accept={acceptFileTypes}
                        hidden
                        multiple={false}
                        onChange={handleUpload}
                        disabled={disabled}
                      />
                    </Grid>
                  )}
                  <Grid item xs={11} margin="auto">
                    <Typography
                      fontSize={!isMobile ? "0.7em" : "0.6em"}
                      color={theme.palette.secondary.main}
                      textTransform="inherit"
                      fontWeight={800}
                    >
                      {documentTypeDescription || (
                        <>
                          {`${t("document-type-be")} `}
                          <b>PDF</b>, <b>JPEG</b> {`${t("or")}`} <b>PNG</b>
                        </>
                      )}
                    </Typography>
                  </Grid>
                </Grid>
              </Button>
            </Grid>
            {item.template && (
              <Grid item xs={11} margin="auto">
                <Link
                  href={item.template}
                  target="_blank"
                  rel="noopener"
                  fontWeight="bold"
                  fontSize={!isMobile ? "1em" : "0.7em"}
                >
                  Download Template
                </Link>
              </Grid>
            )}

            {isMobile && (
              <Grid item xs={12}>
                <Divider />
              </Grid>
            )}
            {isMobile && (
              <Grid
                item
                xs={12}
                sx={{ m: 1 }}
                role="presentation"
                onClick={toggleOpen}
                onKeyDown={handleClose}
              >
                <Button
                  variant="outlined"
                  fullWidth
                  sx={{ fontSize: "0.7em", height: "auto !important" }}
                  onClick={handleOpen}
                >
                  More Info
                </Button>

                <SwipeableDrawer
                  anchor="bottom"
                  open={open}
                  onClose={handleOpen}
                  onOpen={handleClose}
                  PaperProps={{
                    sx: {
                      borderRadius: `${theme.shape.borderRadius * 9}px ${
                        theme.shape.borderRadius * 9
                      }px 0 0`,
                    },
                  }}
                >
                  <Box
                    sx={{
                      width: "30px",
                      height: "6px",
                      backgroundColor: "rgb(224, 224, 224)",
                      borderRadius: "3px",
                      position: "absolute",
                      top: "8px",
                      left: "calc(50% - 15px)",
                    }}
                  ></Box>
                  <Box sx={{ p: 2 }}>
                    {item?.description !== "" && (
                      <Typography
                        fontWeight={600}
                        sx={{ textDecoration: "underline" }}
                      >
                        {item.value}
                      </Typography>
                    )}
                    <Typography>
                      {item?.description !== ""
                        ? item?.description
                        : item.value}
                    </Typography>
                  </Box>
                </SwipeableDrawer>
              </Grid>
            )}
            {Boolean(documents?.length) &&
              documents?.map((document, idx) => (
                <Fragment key={`${document.id}`}>
                  <Grid item xs={11} margin="auto">
                    <Divider />
                  </Grid>
                  <Grid item xs={11} margin="1vh auto">
                    <Grid container alignItems="start">
                      <Grid item lg={9} xs={8}>
                        <Grid container>
                          <Grid
                            item
                            lg={6}
                            className={`${styles.uploadBtnPlaceholder}`}
                          >
                            <Typography
                              fontSize={!isMobile ? "0.8em" : "0.6em"}
                              fontWeight={600}
                              sx={{ wordBreak: "break-word" }}
                            >
                              {document?.name?.split("-")[4] ?? document?.name}
                              {document?.year ? `- ${document?.year}` : ""}
                            </Typography>
                          </Grid>
                          <Grid
                            item
                            lg={6}
                            className={`${styles.uploadBtnPlaceholder}`}
                          >
                            <Typography
                              fontSize={!isMobile ? "0.7em" : "0.6em"}
                              color={theme.palette.secondary.main}
                            >
                              {`${t("submitted-on")}`}{" "}
                              {moment(document?.submitted).format("YYYY/MM/DD")}
                            </Typography>
                          </Grid>
                        </Grid>
                      </Grid>
                      <Grid item lg={3} xs={4}>
                        <Grid
                          container
                          justifyContent="end"
                          alignItems="center"
                          spacing={1}
                        >
                          <Grid item>
                            <Button
                              sx={{
                                fontSize: !isMobile ? "0.8em" : "0.6em",
                                height: "auto !important",
                                padding: 0,
                              }}
                              disabled={viewDisabled}
                              onClick={() => {
                                onView(document?.name, document?.id, idx);
                              }}
                            >
                              View
                            </Button>
                          </Grid>
                          {onDelete && (
                            <Grid item>
                              <IconButton
                                edge="start"
                                color="inherit"
                                sx={{ p: 0 }}
                                disabled={deleteDisabled}
                                onClick={() =>
                                  onDelete(document?.name, document?.id, idx)
                                }
                              >
                                <DeleteIcon
                                  color={deleteDisabled ? "disabled" : "error"}
                                  fontSize={!isMobile ? "large" : "medium"}
                                />
                              </IconButton>
                            </Grid>
                          )}
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </Fragment>
              ))}

            {hasYearTagging &&
              item.count_unique_uploaded !== item.yearly_set_count && (
                <>
                  <Grid item xs={11} margin="auto">
                    <Divider sx={{ borderColor: "rgb(0 0 0 / 68%)" }} />
                  </Grid>
                  <Grid item xs={11} lg={11}>
                    <Grid
                      container
                      spacing={1}
                      alignItems="center"
                      justifyContent="start"
                      marginBottom="1ch"
                    >
                      <Grid item xs={12} lg={12} margin="auto">
                        <Drop
                          label="Select Year"
                          error={isError}
                          placeholder="Select Year"
                          name={`year-${toCamelCase(item.label)}`}
                          keyValue="value"
                          value={selectedYear}
                          sx={{
                            ".MuiOutlinedInput-input": { padding: "7px" },
                          }}
                          data={generateLastThreeYears().map((year) => ({
                            label: `${year}`,
                            value: `${year}`,
                          }))}
                          disabled={!Boolean(file)}
                          setValue={(value: string) => {
                            setSelectedYear(value);
                            if (file)
                              onUpload(
                                { file, year: Number(value) },
                                toCamelCase(item.label),
                                item.id,
                                index
                              );
                          }}
                          multilevel={false}
                          NestedMenuProps={{
                            anchorOrigin: {
                              vertical: "top",
                              horizontal: "right",
                            },
                            sx: nestedMenuSx,
                          }}
                          MenuProps={{
                            sx: menuSx,
                          }}
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                </>
              )}
          </Fragment>
        )}
      </Grid>
    </FormControl>
  );
};
export default DocumentUploadField;

import LoadingButton from "@mui/lab/LoadingButton";
import {
  Alert,
  AlertTitle,
  Chip,
  Divider,
  Grid,
  Stack,
  Typography,
  useTheme,
} from "@mui/material";
import { useUpdateAccountMutation } from "api/api-accounts";
import { ONBOARDING } from "codes";
import MainCard from "components/Common/Cards/MainCard";
import DateInput from "components/Common/DateInput";
import Input from "components/Common/Input";
import { useFormikContext } from "formik";
import moment from "moment";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import { useAppSelector } from "redux/hooks";
import { RegistrationDetailsKeyMappings } from "../CompanyDetailsKeyMappings";
import ShippingAddress from "../ShippingAddress";
import styles from "../company.module.scss";
import { CompanyDetails } from "../validationSchema";

interface IRegistrationDetailsView {
  isPartner?: boolean;
}
const RegistrationDetails = ({
  isPartner = false,
}: IRegistrationDetailsView) => {
  const theme = useTheme();
  const formikProps = useFormikContext<CompanyDetails>();
  const { t } = useTranslation();
  const [updateCompany, { isLoading }] = useUpdateAccountMutation();
  const { shipping_address, country } = formikProps.values;
  const APP_STATE = useAppSelector((state) => state.appState.value);

  const isShippingAddressCompleted =
    shipping_address?.address_line1 !== "" &&
    shipping_address?.postcode !== "" &&
    shipping_address?.city !== "" &&
    shipping_address?.country !== "" &&
    shipping_address?.country !== "";
  const registrationDetailsKeyMappings = RegistrationDetailsKeyMappings(t);

  const missing_fields = Object.keys(registrationDetailsKeyMappings).filter(
    (item) => {
      if (item === "shipping_address" && !isShippingAddressCompleted)
        return true;
      return !(formikProps.values as Record<string, any>)[item];
    }
  );
  const hasMissingFields =
    Boolean(missing_fields.length) && APP_STATE === ONBOARDING;
  useEffect(() => {
    formikProps.setTouched({});
  }, []);

  const { values, dirty } = formikProps;

  return (
    <MainCard
      border
      content
      boxShadow
      shadow={theme.shadows[16]}
      title={
        <Typography fontWeight="bold" variant="h3">
          {t("registration-details")}
        </Typography>
      }
      footer={
        <Grid
          item
          xs={12}
          lg={2}
          ml="auto"
          my={1}
          justifyContent="flex-end"
        >
          <LoadingButton
            variant="contained"
            fullWidth
            data-testid="save-registration-details"
            loading={isLoading}
            disabled={!dirty}
            onClick={() => {
              const updateAccountValues: Record<string, any> = {
                name: values.name,
                reg_no: values.reg_no,
                incorporation_date: moment(values.incorporation_date).format(
                  "YYYY-MM-DD"
                ),
                address_line1: values.address_line1,
                address_line2: values.address_line2,
                city: values.city,
                postcode: values.postcode,
              };
              if (APP_STATE === ONBOARDING) {
                updateAccountValues["shipping_address"] = {
                  ...values.shipping_address,
                  country: values.shipping_address?.iso3,
                };
              }
              const filteredValues = Object.fromEntries(
                Object.entries(updateAccountValues).filter(
                  ([_, value]) =>
                    value != null && value !== undefined && value !== ""
                )
              );

              updateCompany({
                id: values.id,
                ...filteredValues,
              })
                .unwrap()
                .then(() => {
                  toast(`Company details saved successfully`, {
                    type: "success",
                  });
                  formikProps.resetForm({ values });
                })
                .catch((error) => {
                  const errorFile = error?.message;
                  toast(`Something went wrong : ${errorFile}`, {
                    type: "error",
                  });
                });
            }}
          >
            {t("save")}
          </LoadingButton>
        </Grid>
      }
    >
      <Stack spacing={1}>
        {hasMissingFields && (
          <Alert severity="error">
            <AlertTitle>Missing Fields</AlertTitle>
            <Stack
              spacing={1}
              direction="row"
              flexWrap="wrap"
              display="flex"
              rowGap="1ch"
            >
              {missing_fields.map((error) => (
                <Chip label={`${registrationDetailsKeyMappings[error]}`} />
              ))}
            </Stack>
          </Alert>
        )}
        <Grid container spacing={1}>
          <Grid item lg={6} xs={12}>
            <Input
              labelClassName={styles.labelClass}
              name="name"
              required
              label={`${t("company-full-legal-name")}`}
              placeholder={`${t("company-full-legal-name")}`}
              className={styles.formInput}
              {...formikProps}
            />
          </Grid>
          <Grid item lg={6} xs={12}>
            <Input
              labelClassName={styles.labelClass}
              name="reg_no"
              required
              label={`${t("company-registration-number")}`}
              placeholder={`${t("company-registration-number")}`}
              className={styles.formInput}
              {...formikProps}
            />
          </Grid>

          <Grid item xs={12}>
            <Typography
              variant="h4"
              sx={{
                fontWeight: "bolder",
              }}
            >
              {t("company-registered-address")}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Input
              labelClassName={styles.labelClass}
              name="address_line1"
              required
              label={`${t("address")} 1`}
              placeholder={`${t("street")} 1`}
              style={{ width: "100%" }}
              {...formikProps}
            />
          </Grid>

          <Grid item xs={12}>
            <Input
              labelClassName={styles.labelClass}
              name="address_line2"
              label={`${t("address")} 2`}
              placeholder={`${t("street")} 2`}
              style={{ width: "100%" }}
              {...formikProps}
            />
          </Grid>
          <Grid item xs={12}>
            <Input
              name="website"
              label={`${t("website")}`}
              placeholder={`${t("website")}`}
              className={styles.formInput}
              {...formikProps}
            />
          </Grid>
          <Grid item lg={6} xs={12}>
            <DateInput
              labelClassName={styles.labelClass}
              name="incorporation_date"
              label={`${t("company-incorporation-date")}`}
              required
              value={
                formikProps.values.incorporation_date !== undefined &&
                formikProps.values.incorporation_date !== null
                  ? new Date(formikProps.values.incorporation_date as Date)
                  : undefined
              }
              error={formikProps.errors.incorporation_date as string}
              onChangeCommitted={(date) => {
                formikProps.setFieldTouched("incorporation_date", true);
                formikProps.validateField("incorporation_date");
              }}
              {...formikProps}
            />
          </Grid>
          <Grid item lg={6} xs={12}>
            <Input
              labelClassName={styles.labelClass}
              name="city"
              label={`${t("city")}`}
              required
              placeholder={`${t("city")}`}
              style={{ width: "100%" }}
            />
          </Grid>
          <Grid item lg={6} xs={12}>
            <Input
              labelClassName={styles.labelClass}
              name="country"
              label={`${t("country")}`}
              required
              disabled
              style={{ width: "100%" }}
              {...formikProps}
            />
          </Grid>
          <Grid item lg={6} xs={12}>
            <Input
              labelClassName={styles.labelClass}
              name="postcode"
              required
              label={`${t("postcode")}`}
              placeholder={`${t("postcode")}`}
              style={{ width: "100%" }}
              {...formikProps}
            />
          </Grid>
          {APP_STATE === ONBOARDING && (
            <Grid item xs={12}>
              <Divider />
            </Grid>
          )}

          {APP_STATE === ONBOARDING && <ShippingAddress isDisabled={false} />}
        </Grid>
      </Stack>
    </MainCard>
  );
};
export default RegistrationDetails;
